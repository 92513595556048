import { Button, ButtonGroup, Dialog, DialogContent, Divider, TextField, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { pettyCashApiNm, pettyCashColNm } from "./constant"
import PayComponent from "./PayComponent"
import { initReimbursementDialogData } from "./initData"
import { UserContext } from "contexts/UserContext"
import { alertError } from "components/Alert"
import dayjs from "dayjs"
import { DesktopDatePicker } from "@mui/x-date-pickers-pro"

const ReimbursementDialog = ({ dialogOpen, setDialogOpen, onFinish, getFn, getArgs, isFromJobOrder, joData, usrAccId, pcItmData }) => {

  const { user } = useContext(UserContext)
  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initReimbursementDialogData })

  const initReimbursementDialogDataMemo = useMemo(() => ({
    ...initReimbursementDialogData,
    PCJnlId: msData.pcJournalCombo.find(item => item.IsDf)?.id || null
  })
    , [msData.pcJournalCombo])

  const textFieldProps = useCallback((name) => ({
    label: pettyCashColNm[name],
    size: "small",
    value: dialogData[name],
    onChange: (e) => setDialogData(o => ({ ...o, [name]: e.target.value })),
  }), [dialogData])

  const dateTimePickerProp = useCallback((name) => ({
    label: pettyCashColNm[name],
    inputFormat: "DD/MM/YYYY",
    mask: "__/__/____",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} />,
  }), [dialogData])

  const handleDialogOk = useCallback(() => {
    console.log("dialogData::", dialogData)
    if (!dialogData.Amnt || !dialogData.UsrAccId || !dialogData.ExpId || !dialogData.PayTm) {
      alertError("กรุณากรอกข้อมูลให้ครบถ้วน")
      return
    }
    const postData = {
      transactionArgs: {
        ...dialogData,
        AdmAccId: user.AccId,
        isReimbursement: true,
      },
      itemArgs: {
        ...dialogData,
        AdmAccId: user.AccId,
        PCItmTm: dialogData.PayTm,
        Dscp: dialogData.ReimDscp,
        Rmk: dialogData.ReimRmk,
        isReimbursement: true,
      },
    }
    if (isFromJobOrder) {
      const resturnData = {
        ...postData.itemArgs,
        canRemove: true,
        UsrNNm: msData.accountAllCombo.find(acc => acc.id === dialogData.UsrAccId)?.label || "",
        ExpNm: msData.expObj[dialogData.ExpId]?.ExpNm || "",
        postData: postData
      }
      onFinish(resturnData)
      setDialogOpen(false)
    } else {
      postData.getFn = getFn
      postData.getArgs = getArgs
      ax.post(pettyCashApiNm.insertReimbursment, postData).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false)
        }
      })
    }
  }, [ax, dialogData, onFinish, setDialogOpen, getFn, getArgs, user.AccId, isFromJobOrder, msData.accountAllCombo, msData.expObj])

  useEffect(() => {
    if (dialogOpen) {
      if (joData) {
        if (pcItmData) {
          setDialogData({ ...pcItmData, PayTm: dayjs().format("YYYY-MM-DD HH:mm") })
        } else {

          const takePlc = msData.poiObj[joData.TakePOIId] || ""
          const loc = msData.poiObj[joData.LocPOIId] || ""
          const rtnPlc = msData.poiObj[joData.RtnPOIId] || ""
          setDialogData({
            ...initReimbursementDialogDataMemo,
            JobOrdId: joData.JobOrdId,
            UsrAccId: usrAccId,
            LocText: `(${joData.ContSizeFull || ""})${takePlc}-${loc}-${rtnPlc}`,
            PayTm: dayjs().format("YYYY-MM-DD HH:mm"),
          })
        }
      } else if (usrAccId) {
        setDialogData({
          ...initReimbursementDialogDataMemo,
          UsrAccId: usrAccId,
          PayTm: dayjs().format("YYYY-MM-DD HH:mm"),
        })
      }
    } else {
      setDialogData({ 
        ...initReimbursementDialogDataMemo
        , PayTm: dayjs().format("YYYY-MM-DD HH:mm"),
        UsrAccId: usrAccId, })
    }
  }, [dialogOpen, ax, initReimbursementDialogDataMemo, joData, usrAccId, pcItmData, msData.poiObj])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="เบิกตามใบเสร็จ"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFR pt={2} sx={{ alignItems: "stretch", justifyContent: "center", gap: 2 }}>
          <BoxFC width={400} sx={{ gap: 1 }}>
            <BoxFR width="100%" justifyContent="center">
              <ButtonGroup sx={{ width: "100%" }}>
                <Button fullWidth variant={dialogData.InOutTyp === "O" ? "contained" : "outlined"}
                  onClick={() => { setDialogData(o => ({ ...o, InOutTyp: "O", IsCost: 1 })) }}>จ่ายเงิน</Button>
                <Button fullWidth variant={dialogData.InOutTyp === "I" ? "contained" : "outlined"}
                  onClick={() => { setDialogData(o => ({ ...o, InOutTyp: "I", IsCost: 1 })) }}>รับเงิน</Button>
              </ButtonGroup>
            </BoxFR>
            <PayComponent
              dialogOpen={dialogOpen}
              dialogData={dialogData}
              setDialogData={setDialogData}
              isRequriedUsrAccId={true}
              usrAccId={usrAccId}
              jobOrdId={joData?.JobOrdId} />
          </BoxFC>
          <Divider orientation="vertical" variant="middle" sx={{ height: 240, alignSelf: "center" }} flexItem />
          <BoxFC width={250} sx={{ gap: 1 }}>
            <Typography sx={{ pl: 1 }} fontWeight="bold">ใบเสร็จ-การใช้เงิน</Typography>
            <TextField {...textFieldProps("DocNo")} />
            <DesktopDatePicker {...dateTimePickerProp("DocDte")} />
            <TextField {...textFieldProps("ReimDscp")} />
            <TextField {...textFieldProps("ReimRmk")} />
          </BoxFC>
        </BoxFR>

      </DialogContent>
      <DialogFooter okText="บันทึก"
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default React.memo(ReimbursementDialog)