import { expenseColNm } from "./expenseConstant"
import { jobOrderColNm } from "./jobOrderConstant"

export const expenseJobApiNm = {
  getExpenseJob: "/expense-job/getExpenseJob",
  getCostDetail: "/expense-job/getCostDetail",
  getLastFuelPrice: "/expense-job/getLastFuelPrice",
  insertUpdateFuelPrice: "/expense-job/insertUpdateFuelPrice",
  insertExpenseJob: "/expense-job/insertExpenseJob",
  updateExpenseJob: "/expense-job/updateExpenseJob",
  updateExpenseJobColumns: "/expense-job/updateExpenseJobColumns",
  deleteExpenseJob: "/expense-job/deleteExpenseJob"
}


export const expenseJobColNm =  {
  JobSName: "ชื่องาน",
  ExpNm: expenseColNm.ExpNm,
  ContNo: jobOrderColNm.ContNo,
  ContSize: jobOrderColNm.ContSize,
  ContTyp: jobOrderColNm.ContTyp,
  TlrTyp: jobOrderColNm.TlrTyp,
  TlrTypId: jobOrderColNm.TlrTypId,
  ShpmTypId: jobOrderColNm.ShpmTypId,
  ShpmTypNm: jobOrderColNm.ShpmTypNm,
  VatPrct: expenseColNm.VatPrct,
  WhtPrct: expenseColNm.WhtPrct,
  UntPr: expenseColNm.UntPr,
  Prty: expenseColNm.Prty,
  IsOwnRcpt: expenseColNm.IsOwnRcpt,
  Rmk: "หมายเหตุ",
  ModDte: "วันที่แก้ไข",
  ModAcc: "ผู้แก้ไข",
}