/**
 * Invalid for generate serial key
 * Invalid characters are A, E, I, O, U, L, S, 0, 1 and 5
 */

import { MenuItem } from "@mui/material";
import { alertError } from "components/Alert";
import { ACC_TYP_EMP, defaultUserPref } from "constants/accountConstant";
import dayjs from "dayjs";

export const addIdForDataGrid = (data, key) => data.map((item, index) => ({ ...item, No: index + 1, id: item[key] }))

export const validate = (validation) => {
  if (Object.entries(validation).some((item) => item[1])) {
    alertError("กรุณาตรวจสอบข้อมูล");
    return false;
  }
  return true;
}

const cha = {
  A: 10,
  B: 12,
  C: 13,
  D: 14,
  E: 15,
  F: 16,
  G: 17,
  H: 18,
  I: 19,
  J: 20,
  K: 21,
  L: 23,
  M: 24,
  N: 25,
  O: 26,
  P: 27,
  Q: 28,
  R: 29,
  S: 30,
  T: 31,
  U: 32,
  V: 34,
  W: 35,
  X: 36,
  Y: 37,
  Z: 38,
}

const containerNoCheckLocal = (containerNo) => {
  if (!containerNo) {
    return true;
  }
  if (containerNo.length !== 11) {
    // alertError(`หมายเลขตู้ "${containerNo}" ต้องมี 11 หลัก`)
    return false
  }
  if (!/[A-Z][A-Z][A-Z][A-Z][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/.test(containerNo)) {
    // alertError(`รูปแบบหมายเลขตู้ "${containerNo}" ไม่ถูกต้อง`);
    return false
  }
  const cc = containerNo.split("");
  let sum = cha[cc[0]] * 1 +
    cha[cc[1]] * 2 +
    cha[cc[2]] * 4 +
    cha[cc[3]] * 8 +
    parseInt(cc[4]) * 16 +
    parseInt(cc[5]) * 32 +
    parseInt(cc[6]) * 64 +
    parseInt(cc[7]) * 128 +
    parseInt(cc[8]) * 256 +
    parseInt(cc[9]) * 512;
  let mod = sum % 11;
  if (mod === 10) mod = 0;
  if (mod !== parseInt(cc[10])) {
    // alertError(`หมายเลขตู้ "${containerNo}" ไม่ถูกต้อง`);
    return false
  }
  return true;
}
export const containerNoCheck = (containerNos, showAlert = true) => {
  const contNoArr = containerNos.split(",")
  let results = []
  for (const containerNo of contNoArr) {
    if (!containerNoCheckLocal(containerNo.trim())) {
      results.push(containerNo)
    }
  }
  if (results.length > 0) {
    if (showAlert) {
      alertError(`หมายเลขตู้ ${results.join(", ")} ไม่ถูกต้อง`)
    }
    return false
  }
  return true
}
export const getISOCd = (contSize, contTyp, contHq) => {
  let hqStr = "";
  if (contHq === 1) hqStr = "HQ"
  const fullSize = `${contSize}${contTyp}${hqStr}`
  if (fullSize === "20GP") return "22G1"
  else if (fullSize === "20GPHQ") return ""
  else if (fullSize === "20OT") return "22U1"
  else if (fullSize === "20OTHQ") return ""
  else if (fullSize === "20FR") return "22P3"
  else if (fullSize === "20FRHQ") return ""
  else if (fullSize === "20RE") return "22R1"
  else if (fullSize === "20REHQ") return ""
  else if (fullSize === "20Tank") return "22K2"
  else if (fullSize === "20TankHQ") return ""
  else if (fullSize === "40GP") return "42G1"
  else if (fullSize === "40GPHQ") return "45G1"
  else if (fullSize === "40OT") return "42U1"
  else if (fullSize === "40OTHQ") return ""
  else if (fullSize === "40FR") return "42P3"
  else if (fullSize === "40FRHQ") return "45P3"
  else if (fullSize === "40RE") return "42R1"
  else if (fullSize === "40REHQ") return "45R1"
  else if (fullSize === "40Tank") return ""
  else if (fullSize === "40TankHQ") return ""
  else if (fullSize === "45GPHQ") return "L5G1"
  else return ""
}


export const loadMSData = async (ax, setMSData, setUser, setBadge) => {
  const value = await ax.post("/core/getMasterData", {})
  /*
  *
  * cannot use ...msData in obj to get getter Cus and job 
  * like mozilla said https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get#description
  * so have to put getter every time that set new states
  * 
  * 2022-01-29 
  * too much problem with get fn. (hard to reassign value)
  * use static value instead
  */
  const userData = { ...value.data.user }
  if (setUser) {
    setUser(userData);

    localStorage.setItem("userData", JSON.stringify(userData))
  }

  if (setBadge) {
    const badgeData = value.data.badge
    setBadge(badgeData)
    localStorage.setItem("badgeData", JSON.stringify(badgeData))
  }

  delete value.data.user;
  let cusCombo = value.data.customers.map(item => ({ id: item.CusId, label: item.SName }))
  let jobCombo = value.data.jobs.map(item => ({ id: item.JobId, label: item.SName, refId: item.CusId }))
  const truckCombo = value.data.trucks.map(item => ({ id: item.TukId, label: item.Code }))
  const driverOnlyCombo = value.data.drivers.filter(item => item.IsActv && !item.IsSub).map(item => ({ id: item.DrvId, label: item.NName }))
  sortObj(driverOnlyCombo, 'label')

  const tagCombo = value.data.tags ? value.data.tags.map(item => ({ id: item.TagId, label: item.Name, refId: item.TblAbbr })) : []
  const tagObj = value.data.tags ? value.data.tags.reduce((prev, curr) => ({ ...prev, [curr.TagId]: curr.Name }), {}) : {}
  const poiTypeCombo = value.data.poiTypes ? value.data.poiTypes.map(item => ({ id: item.POITypId, label: item.Name })) : []
  const poiTypeObj = value.data.poiTypes.reduce((prev, curr) => ({ ...prev, [curr.POITypId]: curr }), {})
  const poiCombo = value.data.pois ? value.data.pois.map(item => ({ id: item.POIId, label: item.SName, group: poiTypeObj[item.POITypId]?.Name || "", refId: item.POITypId, Prty: poiTypeObj[item.POITypId].Prty })) : []
  const poiObj = value.data.pois ? value.data.pois.reduce((prev, curr) => ({ ...prev, [curr.POIId]: curr.SName }), {}) : {}
  const containerStatuses = sortObj(value.data.containerStatuses.filter(item => item.IsHide === 0), 'Prty')
  const containerStatusObj = containerStatuses.reduce((prev, curr) => ({ ...prev, [curr.ContStsId]: curr }), {})
  const userPref = JSON.parse(userData.UsrPref)

  sortObj(cusCombo, 'label')
  sortObj(jobCombo, 'label')
  sortObj(truckCombo, 'label')

  sortObj(driverOnlyCombo, 'label')
  sortObj(poiCombo, "Prty", "label")
  sortObj(value.data.expenses, "ExpTypId", "Prty")
  if (value.data.cusFrequent) {
    const cusTop10 = []
    cusCombo = cusCombo.filter(item => {
      const found = value.data.cusFrequent.find(cus => cus.CusId === item.id)
      if (found) {
        cusTop10.push({ ...item, group: `ลูกค้า Top ${value.data.serverData?.Feature?.NumCusFrequent || 10}`, num: found.Num })
        return false
      } else {
        item.group = "ลูกค้าทั่วไป"
        return true
      }
    })
    sortObjDesc(cusTop10, 'num')
    cusCombo = [...cusTop10, ...cusCombo]
  }
  if (value.data.jobFrequent) {
    const jobTop10 = []
    jobCombo = jobCombo.filter(item => {
      const found = value.data.jobFrequent.find(job => job.JobId === item.id)
      if (found) {
        jobTop10.push({ ...item, group: `งาน Top ${value.data.serverData?.Feature?.NumJobFrequent || 10}`, num: found.Num })
        return false
      } else {
        item.group = "งานทั่วไป"
        return true
      }
    })
    sortObjDesc(jobTop10, 'num')
    jobCombo = [...jobTop10, ...jobCombo]
  }


  const cusComboWithAdd = cusCombo.map(item => item)
  cusComboWithAdd.push({ id: -1, label: "เพิ่มลูกค้าใหม่", group: "เพิ่มข้อมูล" })
  const poiComboWithAdd = poiCombo.map(item => item)
  poiComboWithAdd.push({ id: -1, label: "เพิ่มสถานที่ใหม่", group: "เพิ่มข้อมูล" })

  const expTypObj = value.data.expenseTypes.reduce((prev, curr) => ({ ...prev, [curr.ExpTypId]: curr }), {})

  const expObj = value.data.expenses.reduce((prev, curr) => ({ ...prev, [curr.ExpId]: curr }), {})
  const favoriteExpense = userPref?.FavoriteExpense || defaultUserPref.DefaultFavoriteExpense
  const favoriteExpIds = favoriteExpense.map(item => item.ExpId)
  const expFiltered =  value.data.expenses.filter(item=>!favoriteExpIds.includes( item.ExpId) ).map(item => (
    { id: item.ExpId, label: item.ExpNm, group: expTypObj[item.ExpTypId]?.ExpTypNm || "", refId: item.ExpTypId }
  ))
  const favExp = favoriteExpense.map(item => ({
    id: item.ExpId, 
    label: expObj[item.ExpId]?.ExpNm || "", 
    group: "ที่ใช้บ่อย", 
    refId: expObj[item.ExpId]?.ExpTypId,
    isFav: 1
  }))
  const expCombo = [
    ...favExp,
    ...expFiltered
  ]
  const expOprCombo = [
    ...favExp,
    ...expFiltered.filter(item=>item.id !== 1)
  ]


  let empOnlyData = {}

  /**
   * msData for employee only
   */
  if (userData.AccTypId === ACC_TYP_EMP) {
    const subContractCombo = value.data.drivers.filter(item => item.IsActv && item.IsSub).map(item => ({ id: item.DrvId, label: item.NName, group: "รถร่วม" }))
    sortObj(subContractCombo, 'label')
    const accountObj = value.data.accounts?.reduce((prev, curr) => ({ ...prev, [curr.AccId]: curr }), {}) || {}
    const accountCombo = value.data.accounts.map(item => ({ id: item.AccId, label: item.NName }))
    sortObj(accountCombo, 'label')
    const accountAllCombo = [
      ...driverOnlyCombo.map(item => ({ ...item, group: "พนักงานขับรถ" })),
      ...accountCombo.map(item => ({ ...item, group: "พนักงานออฟิส" })),
      ...subContractCombo
    ]

    const dfPCJnlId = userPref?.GeneralData?.DfPCJnlId || null
    const pcJournalCombo = value.data.pcJournals.map(item => ({ id: item.PCJnlId, label: item.PCJnlNm, IsDf: item.PCJnlId === dfPCJnlId }))
    const driverCombo = value.data.drivers.filter(item => item.IsActv).map(item => ({ id: item.DrvId, label: item.NName, group: item.IsSub ? "รถร่วม" : "บริษัท" }))

    sortObj(driverCombo, 'group', 'label')

    

    empOnlyData = {
      driverCombo,
      accountObj,
      accountCombo,
      accountAllCombo,
      subContractCombo,
      expOprCombo: expOprCombo,
      expenseTypes: value.data.expenseTypes,
      expTypObj: expTypObj,
      expTypCombo: value.data.expenseTypes.map(item => (
        { id: item.ExpTypId, label: item.ExpTypNm }
      )),
      payMethods: value.data.payMethods,
      payMethodCombo: value.data.payMethods.map(item => (
        { id: item.PayMthdId, label: item.Name }
      )),
      bankAccounts: value.data.bankAccounts,
      bankAccountCombo: value.data.bankAccounts.map(item => (
        { id: item.BkAccId, label: item.SName }
      )),
      banks: value.data.banks,
      bankCombo: value.data.banks.map(item => (
        { id: item.BkId, label: item.Name }
      )),
      pcJournalCombo: pcJournalCombo,
    }
  }
  const msData = {
    ...value.data,
    containerStatuses,
    containerStatusObj,
    isLoaded: true,
    customers: value.data.customers,
    cusCombo: cusCombo,
    cusComboWithAdd: cusComboWithAdd,
    jobCombo: jobCombo,
    tagCombo: tagCombo,
    tagObj: tagObj,
    truckCombo: truckCombo,
    truckObj: value.data.trucks.reduce((prev, curr) => ({ ...prev, [curr.TukId]: curr }), {}),
    driverOnlyCombo: driverOnlyCombo,
    driverObj: value.data.drivers.reduce((prev, curr) => ({ ...prev, [curr.DrvId]: curr }), {}),
    ShpmTypCombo: value.data.shipmentTypes.map(item => (
      { id: item.ShpmTypId, label: item.Name }
    )),
    ShpmTypObj: value.data.shipmentTypes.reduce((prev, curr) => ({ ...prev, [curr.ShpmTypId]: curr.Name }), {}),
    contSizeCombo: value.data.containerSizes.map(item => (
      { id: item.Name, label: item.Name }
    )),
    contTypCombo: value.data.containerTypes.map(item => (
      { id: item.Name, label: item.Name }
    )),
    tlrTypCombo: value.data.trailerTypes.map(item => (
      { id: item.TlrTypId, label: item.Name }
    )),
    poiTypeCombo: poiTypeCombo,
    poiCombo: poiCombo,
    poiComboWithAdd,
    poiObj: poiObj,
    expenses: value.data.expenses,
    expCombo: expCombo,
    expObj: expObj,
    ...empOnlyData
  }
  if (userData.AccTypId === ACC_TYP_EMP) {
    sortObj(msData.permissions, "Prty")
  }
  console.log("user::", userData)
  console.log("msData::", msData)
  setMSData(msData)
  localStorage.setItem("msData", JSON.stringify(msData))
  return msData
}

export const calculateRowClosingColor = (params) => {
  if (params.row.ShpmTypId !== 1) return "";
  if (!params.row.RtnDteEn) return "";
  if (params.row.FinDte) return "";
  if (dayjs() > dayjs(params.row.RtnDteEn)) {
    return "container-closing-red";
  } else if (dayjs() > dayjs(params.row.RtnDteEn).subtract(1, "day")) {
    return "container-closing-yellow";
  } else return "";
}

export const dateFormat = (dte, nullStr) => {
  return (dte && dayjs(dte).format("DD/MM/YYYY")) || nullStr || "-"
}

export const dateTimeDiff = (dte1, dte2) => {

  if (!dte1 || !dte2) {
    return "-"
  }
  const minute = dayjs(dte1).diff(dayjs(dte2), "minute")
  const hour = dayjs(dte1).diff(dayjs(dte2), "hour")
  const day = dayjs(dte1).diff(dayjs(dte2), "day")
  const month = dayjs(dte1).diff(dayjs(dte2), "month")
  const year = dayjs(dte1).diff(dayjs(dte2), "year")

  if (year !== 0) {
    return `${year}ปี ${month % 12}เดือน`
  } else if (month !== 0) {
    return `${month}เดือน ${day % 30}วัน`
  } else if (day !== 0) {
    return `${day}วัน ${hour % 24}ชม.`
  } else if (hour !== 0) {
    return `${hour}ชม. ${minute % 60}นาที`
  } else {
    return `${minute}นาที`
  }
}

export const numberFormat = (number, isNullEmptyString) => {
  // console.log("number::", number);
  // console.log("10:", 10)
  if (!number || isNaN(number)) return isNullEmptyString ? "" : "0.00"
  const num2Point = Math.round(number * 100) / 100
  return num2Point.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export const thousandFormat = (number) => {
  if (!number || isNaN(number)) return "0.00"
  if (+number >= 1000000) {
    return `${Math.round(+number / 100000) / 10}M`
  } else if (+number >= 1000) {
    return `${Math.round(+number / 100) / 10}K`
  } else {
    const num2Point = Math.round(number * 100) / 100
    return num2Point.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
}
export const numberFormatNoFlotingPoint = (number, isNullEmptyString) => {
  //console.log("number::", number);
  // console.log("10:", 10)
  if (!number || isNaN(number)) return isNullEmptyString ? "" : "0"
  const num = number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return num.substr(0, num.length - 3)
}
export const numberformatSmart = (number, isNullEmptyString) => {
  return number.toString().includes(".") ? numberFormat(number, isNullEmptyString) : numberFormatNoFlotingPoint(number, isNullEmptyString)

}
export const toNumber = (numberStr) => {
  if (typeof numberStr === 'string' || numberStr instanceof String) {

    return Number(numberStr.replaceAll(",", ""))
  } else if (typeof numberStr == 'number' || numberStr instanceof Number) {
    return numberStr
  } else {
    return 0
  }
}

export const isNumeric = (value) => {
  return !isNaN(value - parseFloat(value));
}
export const generateMenuItemWithNull = (comboArr) => {
  if (!comboArr) return null
  return [
    <MenuItem key={0} value={null}>ไม่ระบุ</MenuItem>,
    ...comboArr.map(item => <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>)
  ]
}
export const generateMenuComboItem = (list) => (

  list.map(item => (
    <MenuItem key={item.id} value={item.id}>
      {item.label}
    </MenuItem>
  ))
)

// export const sortObj = (obj, key) => {
//   obj.sort((a, b) => {
//     if (a[key] < b[key]) {
//       return -1;
//     }
//     if (a[key] > b[key]) {
//       return 1;
//     }
//     return 0;
//   })
//   return obj;
// }

export const sortObj = (obj, key1, key2) => {
  obj.sort((a, b) => {

    if (a[key1] < b[key1]) {
      return -1;
    } else if (a[key1] > b[key1]) {
      return 1;
    } else { //if equal compare key2
      if (key2) {
        if (a[key2] < b[key2]) {
          return -1
        } else if (a[key2] > b[key2]) {
          return 1
        } else return 0
      } else return 0
    }
  })
  return obj;
}

export const sortObjDesc = (obj, key1, key2) => {
  obj.sort((a, b) => {

    if (a[key1] > b[key1]) {
      return -1;
    } else if (a[key1] < b[key1]) {
      return 1;
    } else { //if equal compare key2
      if (key2) {
        if (a[key2] > b[key2]) {
          return -1
        } else if (a[key2] < b[key2]) {
          return 1
        } else return 0
      } else return 0
    }
  })
  return obj;
}

export const sortObjByDteTm = (obj, key) => {
  obj.sort((a, b) => {
    if (dayjs(a[key]) < dayjs(b[key])) {
      return -1;
    }
    if (dayjs(a[key]) > dayjs(b[key])) {
      return 1;
    }
    return 0;
  })
}

export const addNoDuplicate = (originalArr, addArr, key) => [
  ...originalArr,
  ...addArr.filter(item => !originalArr.some(itemO => itemO[key] === item[key]))
]

export const moveRow = (arr, index, num) => {
  if (index === 0 && num < 0) {
    return arr
  }
  if (index === arr.length - 1 && num > 0) {
    return arr
  }
  const item = arr[index]
  arr.splice(index, 1)
  arr.splice(index + num, 0, item)
  item.order += num;
  arr[index].order += num * -1
  return [...arr]
}

export const moveRowTo = (arr, from, to) => {
  const rowsClone = [...arr];
  const row = rowsClone.splice(from, 1)[0];
  rowsClone.splice(to, 0, row);
  return rowsClone
}

export const addStatusForClientDailyTable = (dailyJobData, msDataContSts) => {
  dailyJobData.forEach(item => {
    item.Status = "เตรียมการ"
    if (item.FinDte) {
      item.Status = "วิ่งงานจบ"
      return
    }
    for (let i = msDataContSts.length - 1; i >= 0; i--) {
      if (item[`DrvSts${msDataContSts[i].ContStsId}`]) {
        item.Status = `${msDataContSts[i].Name}`
        if (item[`DrvSts${msDataContSts[i].IsCmpt}`]) {
          item.Status += "(Done)"
        }
        break;
      }
    }
  });
  return dailyJobData
}

export const convertMonthToTHMonth = (numMonth, isAbbr) => {
  const numMonthInt = +numMonth
  if (numMonthInt === 1) {
    return isAbbr ? "ม.ค." : "มกราคม"
  } else if (numMonthInt === 2) {
    return isAbbr ? "ก.พ." : "กุมพาพันธ์"
  } else if (numMonthInt === 3) {
    return isAbbr ? "มี.ค." : "มีนาคม"
  } else if (numMonthInt === 4) {
    return isAbbr ? "เม.ย." : "เมษายน"
  } else if (numMonthInt === 5) {
    return isAbbr ? "พ.ค." : "พฤษภาคม"
  } else if (numMonthInt === 6) {
    return isAbbr ? "มิ.ย." : "มิถุนายน"
  } else if (numMonthInt === 7) {
    return isAbbr ? "ก.ค." : "กรกฏาคม"
  } else if (numMonthInt === 8) {
    return isAbbr ? "ส.ค." : "สิงหาคม"
  } else if (numMonthInt === 9) {
    return isAbbr ? "ก.ย." : "กันยายน"
  } else if (numMonthInt === 10) {
    return isAbbr ? "ต.ค." : "ตุลาคม"
  } else if (numMonthInt === 11) {
    return isAbbr ? "พ.ย." : "พฤศจิกายน"
  } else if (numMonthInt === 12) {
    return isAbbr ? "ธ.ค." : "ธันวาคม"
  }
}

export const convertYearMonthToTHMonth = (yearMonth, isAbbr) => {
  const year = yearMonth.toString().substr(0, 4)
  const month = yearMonth.toString().substr(4, 2)

  return (`${convertMonthToTHMonth(month, isAbbr)} ${year}`)
}
export const convertToTop5 = (data, keyNum, keyLabel) => {
  const result = []
  data.forEach(item => {
    if (result.length < 5) {
      result.push({ ...item })
    } else if (result.length === 5) {
      result.push({
        ...item,
        [keyLabel]: "อื่นๆ",
        [keyNum]: +item[keyNum]
      })
    } else {
      result[5][keyNum] += +item[keyNum]
    }
  })
  return result
}
export const makePieData = ({ data, keyLabel, label, inChartLabelKey, legendWithNum }) => {
  console.log("makePineData keylabel, data::", keyLabel, data)
  return data.reduce((prev, cur) => {
    prev.labels.push(legendWithNum ? `${cur[keyLabel]}(${numberformatSmart(+cur.Num)})` : cur[keyLabel])
    prev.datasets[0].data.push(cur.Num)
    if (inChartLabelKey) {
      prev.datasets[0].inChartLabels.push(cur[inChartLabelKey])
    }
    return prev
  }, {
    labels: [],
    datasets: [
      {
        label: label,
        data: [],
        inChartLabels: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ]
  })
}

export const selectOnFocus = (e) => e.target.select()

export const isASCII = (str) => /^[\x20-\x7F]+$/.test(str)

export const convertThaiEng = (str) => {
  const enChar = "1234567890-=qwertyuiop[]\\asdfghjkl;'zxcvbnm,./!@#$%^&*()_+QWERTYUIOP{}|ASDFGHJKL:\"ZXCVBNM<>?"
  const thChar = "ๅ/-ภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ+๑๒๓๔ู฿๕๖๗๘๙๐\"ฎฑธํ๊ณฯญฐ,ฅฤฆฏโฌ็๋ษศซ.()ฉฮฺ์?ฒฬฦ"
  let findChar = ""
  let replaceChar = ""
  let numTHChar = 0
  let numENChar = 0
  for (const c of str) {
    if (thChar.indexOf(c) >= 0) numTHChar++
    else numENChar++
  }
  if (numTHChar > numENChar) {
    findChar = thChar
    replaceChar = enChar
  } else {
    findChar = enChar
    replaceChar = thChar
  }
  const result = []
  for (const c of str) {
    const index = findChar.indexOf(c)
    result.push(index >= 0 ? replaceChar[index] : c)
  }
  return result.join("")
}