import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { advColumns, itmColumns } from './columns';
import { AddCircleRounded, InfoRounded, ReceiptRounded } from '@mui/icons-material';
import AddAdvanceDialog from 'pages/Menu1/PettyCash/AddAdvanceDialog';
import { grey, yellow } from '@mui/material/colors';
import ReimbursementDialog from 'pages/Menu1/PettyCash/ReimbursementDialog';
import { alertError } from 'components/Alert';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/UserContext';
import AddItemDialog from 'pages/Menu1/PettyCash/AddItemDialog';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import ChangeDialog from 'pages/Menu1/PettyCash/ChangeDialog';

// let selectedId = null;
let selectedAdvData = null;
let selectedItmData = null;
let deletedIds = []
let initChangeDialogData = null

const PettyCash = ({ pettyCashData, setPettyCashData, contStatusData, joData }) => {

  const { user } = useContext(UserContext)
  const { msData } = useContext(GlobalStateContext)

  const [dialogAddAdvanceOpen, setDialogAddAdvanceOpen] = useState(false)
  const [dialogAddItemOpen, setDialogAddItemOpen] = useState(false)
  const [dialogReimbursementOpen, setDialogReimbursementOpen] = useState(false)
  const [dialogChangeOpen, setDialogChangeOpen] = useState(false)

  const handleAddAdvance = useCallback(() => {
    selectedAdvData = null
    setDialogAddAdvanceOpen(true)
  }, [])

  const handleAddReimbursement = useCallback(() => {
    selectedItmData = null
    setDialogReimbursementOpen(true)
  }, [])

  const handleCellClick = useCallback((params) => {
    if (params.field === 'ShowDetail') {
      // selectedId = params.id
      // setDialogOpen(true)
    }
  }, [])
  const handleRowDoubleClick = useCallback((params) => {
    // selectedId = params.id
    // setDialogOpen(true)
  }, [])

  const onAdvanceDialogFinish = useCallback((data, mode) => {
    console.log('onAdvanceDialogFinish data::', data)
    if (mode === "DEL") {
      if (data.PCTrnsId) {
        deletedIds.push(data.PCTrnsId)
      }
      setPettyCashData(o => {
        return { ...o, advData: o.advData.filter(item => item.id !== data.id) }
      })
    } else {
      setPettyCashData(o => {
        //if from edit should have id
        if (data.id) {
          const foundIndex = o.advData.findIndex(item => item.id === data.id)
          if (foundIndex === -1) return o
          const newAdvData = [...o.advData]
          newAdvData[foundIndex] = data
          return { ...o, advData: newAdvData }
        } else {
          const minAdvDataId = o.advData.reduce((prev, cur) => prev < cur.id ? prev : cur.id, 0)
          console.log('minAdvDataId::', minAdvDataId)
          return {
            ...o, advData: [...o.advData, {
              ...data,
              id: minAdvDataId - 1,
              No: o.advData.length + 1,
              ChdAmnt: 0,
              ItmAmnt: 0,
            }]
          }
        }
      })
    }
  }, [setPettyCashData])

  const onReimbursementDialogFinish = useCallback((data) => {
    console.log('onReimbursementDialogFinish data::', data)
    setPettyCashData(o => {
      // if (data.id) {
      //   const foundIndex = o.itmData.findIndex(item => item.id === data.id)
      //   if (foundIndex === -1) return o
      //   const newItmData = [...o.itmData]
      //   newItmData[foundIndex] = data
      //   return { ...o, itmData: newItmData }
      // } else {
      // const minAdvDataId = o.advData.reduce((prev, cur) => prev < cur.id ? prev : cur.id, 0)
      const minItmDataId = o.itmData.reduce((prev, cur) => prev < cur.id ? prev : cur.id, 0)
      console.log('minItmDataId::', minItmDataId)
      return {
        // advData: [...o.advData, {...data.transactionArgs, id: minAdvDataId - 1, No: o.advData.length + 1}], 
        advData: o.advData,
        itmData: [...o.itmData, { ...data, isReimburse: true, id: minItmDataId - 1, No: o.itmData.length + 1 }]
      }
      // }
    })
  }, [setPettyCashData])

  const onAddItemDialogFinish = useCallback((data) => {
    console.log("onAddItemDialogFinish data::", data)

    setPettyCashData(o => {
      //find cliecked row in advData
      const advResult = o.advData.map(item => ({ ...item }))
      const found = advResult.find(item => item.id === data.id)
      console.log("found:: ", found)
      if (!found) return o

      const minItmDataId = o.itmData.reduce((prev, cur) => prev < cur.id ? prev : cur.id, 0)
      const itmData = [...o.itmData, {
        ...data,
        id: minItmDataId - 1,
        No: o.itmData.length + 1,
        canRemove: true,
        PCTrnsId: data.id
      }]
      //if use all amnt remove row from advData
      console.log("advResult::", advResult)
      // if (+found.Amnt - +(found.ChdAmnt || 0) - +(found.ItmAmnt || 0) === data.Amnt) {
      //   console.log("use all Amnt remove row from advData")
      //   return {
      //     advData: advResult.filter(item => item.id !== data.id),
      //     itmData: itmData
      //   }
      // } else {
      //   console.log("not use all Amnt remove row from advData")
        found.ItmAmnt = +(found.ItmAmnt || 0) + +data.Amnt
        return {
          advData: advResult,
          itmData: itmData
        }
      // }
    })
  }, [setPettyCashData])

  const onChangeDialogFinish = useCallback((data) => {
    console.log("in onChangeDialogFinish data::", data)
    setPettyCashData(o => {

      const minAdvDataId = o.advData.reduce((prev, cur) => prev < cur.id ? prev : cur.id, 0)
      const changeData = { ...data, id: minAdvDataId - 1, No: o.advData.length + 1, ChdAmnt: 0, ItmAmnt: 0 }

      let reimburseItem = null
      if (data.reimburseData) {
        const minItmDataId = o.itmData.reduce((prev, cur) => prev < cur.id ? prev : cur.id, 0)
        reimburseItem = {
          ...data.reimburseData.itemArgs,
          id: minItmDataId - 1,
          No: o.itmData.length + 1,
          isReimbursement: true,
          postData: data.reimburseData,
        }
      }
      return {
        advData: [...o.advData, changeData],
        itmData: [...o.itmData, ...(reimburseItem ? [reimburseItem] : [])]
      }
    })
  }, [setPettyCashData])

  const handleEditAdvance = useCallback((row) => {
    console.log('handleEditAdvance row::', row)
    selectedAdvData = row
    setDialogAddAdvanceOpen(true)
  }, [])

  const handleDeleteItem = useCallback((row) => {
    console.log('handleDeleteItem row::', row)


    setPettyCashData(o => {

      if (row.postData?.transactionArgs?.PCTrnsId) { //old code might just for restore AdvData incase useall
        return ({
          ...o,
          itmData: o.itmData.filter(item => item.id !== row.id),
          advData: [...o.advData, row.postData.transactionArgs] 
        })
      } else {
        const itmResult = o.itmData.filter(item => item.id !== row.id)
        const advResult = o.advData.map(item => ({ ...item }))
        const found = advResult.find(item=>item.id === row.PCTrnsId)
        if(found){
          found.ItmAmnt = +(found.ItmAmnt || 0) - +row.Amnt
          return ({
            ...o, 
            itmData: itmResult,
            advData: advResult
          })
        } else {
          return ({
            ...o,
            itmData: itmResult,
            advData: [...o.advData, row]
          })
        }
      }
      // return ({
      //   ...o,
      //   itmData: o.itmData.filter(item => item.id !== row.id),
      //   advData: row.postData?.transactionArgs?.PCTrnsId ? [...o.advData, row.postData.transactionArgs] : [...o.advData]
      // })
    })
  }, [setPettyCashData])

  const handleAddItem = useCallback((row) => {
    console.log("in handelAddItem row::", row)
    if (!row.UsrAccId) {
      alertError("กรุณาระบุผู้รับเงินก่อน")
      return
    }
    selectedAdvData = {
      ...row,
      PCPrdId: null,
      PayTm: dayjs(),
      TakePlc: msData.poiObj[joData.TakePOIId] || "",
      Loc: msData.poiObj[joData.LocPOIId] || "",
      RtnPlc: msData.poiObj[joData.RtnPOIId] || "",
    }
    setDialogAddItemOpen(true)
  }, [msData.poiObj, joData.TakePOIId, joData.LocPOIId, joData.RtnPOIId])

  const handleAddItemUseAll = useCallback((row) => {
    console.log('handleAddItemUseAll row::', row)
    if (!row.UsrAccId) {
      alertError("กรุณาระบุผู้รับเงินก่อน")
      return
    }
    const postData = {
      transactionArgs: { ...row },
      itemArgs: {
        ...row,
        AdmAccId: user.AccId,
        PCItmTm: dayjs(),
        Dscp: "",
        Rmk: ""
      },
    }
    row.postData = postData
    row.canRemove = true
    setPettyCashData(o => {
      return {
        advData: o.advData.filter(item => item.id !== row.id),
        itmData: [...o.itmData, row]
      }
    })
  }, [setPettyCashData, user.AccId])

  const handleReturnChange = useCallback((row) => {
    console.log('handleReturnChange row::', row)
    // setPettyCashData(o => ({
    //   ...o,
    //   advData: [
    //     ...o.advData, 
    //     {
    //       ...row,
    //       InOutTyp: "I",
    //       Dscp: "เงินทอน",
    //     }
    //   ]
    // }))
    selectedAdvData = { ...row, PayTm: dayjs() }
    const diffAmount = +row.Amnt - +(row.ChdAmnt || 0) - +(row.ItmAmnt || 0)
    initChangeDialogData = {
      ...row,
      PayTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      InOutTyp: diffAmount > 0 ? "I" : "O",
      Dscp: diffAmount > 0 ? "เงินทอน" : "เงินเพิ่ม",
      Amnt: Math.abs(diffAmount),
      PrPCTrnsId: row.PCTrnsId,
    }

    setDialogChangeOpen(true)
  }, [])

  const handleSetUserAccId = useCallback((row) => {
    console.log('handleSetUserAccId row::', row)
    setPettyCashData(o=>{
      const newAdvData = o.advData.map(item=>({...item}))
      const found = newAdvData.find(item=>item.id === row.id)
      console.log("found::", found, joData.processDrvId)
      const drvNNm = msData.driverObj[joData.processDrvId]?.NName || ""  
      found.UsrAccId = joData.processDrvId
      found.UsrNNm = drvNNm
      found.isFromProcessDrvId = true
      return {...o, advData: newAdvData}
    })
  }, [joData.processDrvId, setPettyCashData, msData.driverObj])
  const advColumnsMemo = useMemo(() => advColumns({ handleEditAdvance, handleAddItem, handleAddItemUseAll
    , handleReturnChange, handleSetUserAccId })
    , [handleEditAdvance, handleAddItem, handleAddItemUseAll, handleReturnChange, handleSetUserAccId])
  const itmColumnsMemo = useMemo(() => itmColumns(handleDeleteItem)
    , [handleDeleteItem])

    // console.log("pettyCashData::", pettyCashData)
  // console.log("initChangeData::", initChangeDialogData)
  return (
    <BoxFC flex={1} sx={{ gap: 0 }}>
      <BoxFR sx={{ gap: 0 }}>
        <Typography fontWeight="bold">เงินล่วงหน้า</Typography>
        <Tooltip arrow placement='top' title="เงินล่วงหน้าแสดงเฉพาะเงินที่ไม่มีผู้รับผิดชอบ หรือผู้รับผิดชอบคือผู้รับ-ส่งใบงานเท่านั้น">
          <InfoRounded sx={{ color: grey[500] }} fontSize='small' />
        </Tooltip>
        {/* <PersonPin color="primary" /> */}
        <IconButton sx={{ py: 0 }} onClick={handleAddAdvance}>
          <AddCircleRounded color={"primary"} />
        </IconButton>
      </BoxFR>
      <Box flex={1} sx={{ "& .fromProcessDrvId": { bgcolor: yellow[200] } }}>
        <DataGridCellExpand
          density="compact"
          hideToolbar
          hideFooter
          rows={pettyCashData.advData}
          columns={advColumnsMemo}
          onRowDoubleClick={handleRowDoubleClick}
          onCellClick={handleCellClick}
        />
      </Box>
      <BoxFR py={0.5}>
        <Typography fontWeight="bold">ค่าใช้จ่าย</Typography>
        {/* <PersonPin color="primary" /> */}
        <Button size="small"
          // disabled={processDrvId?false:true} 
          variant='contained' onClick={handleAddReimbursement}><ReceiptRounded sx={{ mr: 1 }} /> เบิกตามใบเสร็จ</Button>
        {/* <Box flex={1} /> */}
        {/* <Button size='small' variant='contained'><SearchRounded sx={{ mr: 1 }} /> ดูรายละเอียดค่าใช้จ่ายใบงานนี้</Button> */}
      </BoxFR>
      <Box flex={1} >
        <DataGridCellExpand
          density="compact"
          hideToolbar
          hideFooter
          rows={pettyCashData.itmData}
          columns={itmColumnsMemo}
          onRowDoubleClick={handleRowDoubleClick}
          onCellClick={handleCellClick}
        />
      </Box>
      <AddAdvanceDialog
        pcTrnsId={null}
        dialogOpen={dialogAddAdvanceOpen}
        setDialogOpen={setDialogAddAdvanceOpen}
        onFinish={onAdvanceDialogFinish}
        isFromJobOrder={true}
        usrAccId={joData.processDrvId}
        joData={joData}
        pcTrnsData={selectedAdvData} />

      <AddItemDialog
        maxAmount={(selectedAdvData?.Amnt || 0) - +(selectedAdvData?.ChdAmnt || 0) - +(selectedAdvData?.ItmAmnt || 0)}
        pcItmId={null}
        pcTrnsId={selectedAdvData?.PCTrnsId}
        prPCTrnsData={selectedAdvData}
        dialogOpen={dialogAddItemOpen}
        setDialogOpen={setDialogAddItemOpen}
        onFinish={onAddItemDialogFinish}
        isFromJobOrder={true}
        pcTrnsData={selectedAdvData} />

      <ReimbursementDialog
        dialogOpen={dialogReimbursementOpen}
        setDialogOpen={setDialogReimbursementOpen}
        onFinish={onReimbursementDialogFinish}
        isFromJobOrder={true}
        joData={joData}
        usrAccId={joData.processDrvId}
        pcItmData={selectedItmData} />
      <ChangeDialog
        initData={initChangeDialogData}
        pcTrnsId={null}
        prPCTrnsId={selectedAdvData?.PCTrnsId}
        prPCTrnsData={selectedAdvData}
        dialogOpen={dialogChangeOpen}
        setDialogOpen={setDialogChangeOpen}
        onFinish={onChangeDialogFinish}
        isFromJobOrder={true} />
    </BoxFC>
  );
}

export default PettyCash