import { Route } from "react-router-dom"
import HalfTripReport from "./HalfTripReport/HalfTripReport"
import ConatinerExpenseReport from "./ConatinerExpenseReport/ConatinerExpenseReport"
import JobOrderAllDetail from "./ContainerAllDetail/JobOrderAllDetail"
import ShippingProcess from "./ShippingProcess/ShippingProcess"
import PackingListConfirm from "./PackingListConfirm/PackingListConfirm"
import TruckIncomeSummaryKN from "./TruckIncomSummaryKN/TruckIncomeSummaryKN"
import JobPlaningV2 from "./JobPlaningV2/JobPlaningV2"
import DriverIncome from "./DriverIncome/DriverIncome"
import JobPlaning from "./JobPlaning/JobPlaning"
import BYDData from "./BYDData/BYDData"
import JobOrderAllExpense from "./JobOrderAllExpense/JobOrderAllExpense"

export const branchApiNm = {
  getJobOrderAllDetail: "/branch/getJobOrderAllDetail",
  getTruckIncomeSummary: "/branch/getTruckIncomeSummary"
}

export const extraPageName = {
  JobPlaning: "การวางแผนงาน",
  JobPlaningV2: "การวางแผนงานV2",
  HalfTripReport: "รายงานเที่ยวเต็มเที่ยวตัด",
  ConatinerExpenseReport: "รายงาน คชจ. แยกตามตู้",
  JobOrderAllDetail: "รายละเอียดงานและคชจ.",
  ShippingProcess: "Shipping Process",
  TruckIncomeSummaryKN: "รายงานรายได้แยกตามรถ",
  DriverIncome: "คิดค่าเที่ยว พขร.",
  BYDData: "ส่งข้อมุล BYD",
  JobOrderAllExpense: "คชจ.ใบงาน"
}

export const extraPageMenu = [
  { page: "JobPlaning", num: "8.1", menuName: extraPageName.JobPlaning, prNum: 8 },
  { page: "JobPlaningV2", num: "8.1.1", menuName: extraPageName.JobPlaningV2, prNum: 8 },
  { page: "HalfTripReport", num: "8.2", menuName: extraPageName.HalfTripReport, prNum: 8 },
  { page: "ConatinerExpenseReport", num: "8.3", menuName: extraPageName.ConatinerExpenseReport, prNum: 8,  permission: "FIN" },
  { page: "JobOrderAllDetail", num: "8.4", menuName: extraPageName.JobOrderAllDetail, prNum: 8,  permission: "EXE" },
  { page: "ShippingProcess", num: "8.5", menuName: extraPageName.ShippingProcess, prNum: 8 },
  { page: "TruckIncomeSummaryKN", num: "8.6", menuName: extraPageName.TruckIncomeSummaryKN, prNum: 8, permission: "EXE;FIN"},
  { page: "DriverIncome", num: "8.7 ", menuName: extraPageName.DriverIncome, prNum: 8 },
  { page: "BYDData", num: "8.8 ", menuName: extraPageName.BYDData, prNum: 8 },
  { page: "JobOrderAllExpense", num: "8.9 ", menuName: extraPageName.JobOrderAllExpense, prNum: 8 },

]

export const extraRoute = (path)=> [
  <Route key={"JobPlaning"} path={`${path}/JobPlaning`} ><JobPlaning /> </Route>,
  <Route key={"JobPlaningV2"} path={`${path}/JobPlaningV2`} ><JobPlaningV2 /> </Route>,
  <Route key={"HalfTripReport"} path={`${path}/HalfTripReport`} ><HalfTripReport /> </Route>,
  <Route key={"ConatinerExpenseReport"} path={`${path}/ConatinerExpenseReport`} ><ConatinerExpenseReport /> </Route>,
  <Route key={"JobOrderAllDetail"} path={`${path}/JobOrderAllDetail`} ><JobOrderAllDetail /> </Route>,
  <Route key={"ShippingProcessQuery"} path={`${path}/ShippingProcess/:queryParam/:queryValue`} ><ShippingProcess /> </Route>,
  <Route key={"ShippingProcess"} path={`${path}/ShippingProcess`} ><ShippingProcess /> </Route>,
  <Route key={"TruckIncomeSummaryKN"} path={`${path}/TruckIncomeSummaryKN`} ><TruckIncomeSummaryKN /> </Route>,
  <Route key={"DriverIncome"} path={`${path}/DriverIncome`} ><DriverIncome /> </Route>,
  <Route key={"BYDData"} path={`${path}/BYDData`} ><BYDData/> </Route>,
  <Route key={"JobOrderAllExpense"} path={`${path}/JobOrderAllExpense`} ><JobOrderAllExpense/> </Route>,
]

export const extraMainRoute =  [
  <Route key="shipping" path={`/public/PackingListConfirm/:shpInvId/:token`} ><PackingListConfirm /> </Route>
]

