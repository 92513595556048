// import { BoxFC } from "components/BoxCustom";
// import React from "react";

// const BYDData = () => {
//   return (
//     <BoxFC >
//       <h1>BYD Data</h1>
//     </BoxFC>
//   );
// }

// export default BYDData;


import { Box, Button, TextField, Typography } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { columns } from './columns';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import DateTimeSelect from 'components/DateTimeSelect';
import SearchButton from 'components/buttons/SearchButton';
import ClearButton from 'components/buttons/ClearButton';
import { initFilterData, clearFilterData } from './initData';
import { addIdForDataGrid } from 'utils';
import { jobOrderColNm } from 'constants/jobOrderConstant';
import { branchApiNm } from 'branch/constant/branchApiNm';
import { CloudDownloadRounded, ContentPasteRounded } from '@mui/icons-material';
import { alertError } from 'components/Alert';
import DateTimeDialog from './DateTimeDialog';
import DailyJobDialogV3 from 'pages/Menu1/DailyJob/DailyJobDialogV3';
import SelectImageDialog from './SelectImageDialog';
import FullScreenImageDialog from 'components/FullScreenImageDialog/FullScreenImageDialog';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import TextBoxDialog from './TextBoxDialog';

let selectedJobOrdId = null;
let selectedRow = null;
let lastFilter = null
let oldDateTimeValue = null;
let clickedDateTimeParams = null;
let linkUrlImage = "";

const BYDData = () => {
  const { ax } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [dialogDateTimeOpen, setDialogDateTimeOpen] = useState(false)
  const [dialogJOOpen, setDialogJOOpen] = useState(false)
  const [dialogSelectImageOpen, setDialogSelectImageOpen] = useState(false)
  const [dialogFullScreenImageOpen, setDialogFullScreenImageOpen] = useState(false)
  const [dialogTextBoxOpen, setDialogTextBoxOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...initFilterData })
  const apiRef = useGridApiRef();

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getBYDData, filter).then(value => {
      if (value.data) {
        lastFilter = filter
        setDataTable(addIdForDataGrid(value.data, 'JobOrdId'))
      }
    })
  }, [ax])

  const textFieldProps = useCallback((name) => ({
    variant: 'outlined',
    size: 'small',
    label: jobOrderColNm[name],
    value: filterData[name] || "",
    onChange: (e) => setFilterData(o => ({ ...o, [name]: e.target.value || null })),
    onKeyUp: (e) => { if (e.key === 'Enter') { getData(filterData) } }
  }), [filterData, getData])


  const handleCellClick = useCallback((params) => {
    if (params.field === 'ShowDetail') {
      selectedJobOrdId = params.id
      selectedRow = params.row
      setDialogJOOpen(true)
    } else if (params.field === "SwapData") {
      selectedJobOrdId = params.id
      selectedRow = params.row
      setDialogTextBoxOpen(true)
    } else if (["plannedPickupTime", "plannedArrivalTime", "atd_eventTime", "ata_eventTime", "pod_eventTime"].includes(params.field)) {
      oldDateTimeValue = params.value
      clickedDateTimeParams = params
      setDialogDateTimeOpen(true)
    }
  }, [])

  const handleCellEdit = useCallback((params) => {
    /**
     * 
     * For change multiple row when select. But dont use this coz text never change for multiple row
     * 
     * let selectedIds = []
      if(selectionModel.includes(params.id)){
        selectedIds = selectionModel
      } else {
        selectedIds = [params.id]
      }

      const result = [...o]
      // const filteredRow = result.filter(item => selectedId.includes( item.id))
      for(const row of result){
        if(selectedIds.includes(row.id)){
          row[params.field] = params.value
        }
      }
      return result
     *
     */
    setDataTable(o => {
      const result = [...o]
      const foundRow = result.find(item => item.id === params.id)
      if (foundRow) {
        foundRow[params.field] = params.value
      }
      return result
    })
  }, [])

  const handleAddDriverFromJobOrder = useCallback(() => {
    if (selectionModel.length === 0) {
      alertError("กรุณาเลือกอย่างน้อย 1 รายการ")
      return
    }
    const postData = {
      AptTmSt: filterData.AptTmSt,
      AptTmEn: filterData.AptTmEn,
      getArgs: lastFilter
    }
    ax.post(branchApiNm.getBYDDataFromJobOrder, postData).then(value => {
      if (value.data) {
        setDataTable(o => {
          const result = [...o]
          const filteredData = result.filter(item => selectionModel.includes(item.id))

          for (const row of filteredData) {
            let foundRow = null
            if (row.IsSub) {
              foundRow = value.data.find(item => {
                return !item.IsAdded && item.Cmpy === row.FName && result.find(r => !r.driverIdCardNo || r.driverIdCardNo === item.driverIdCardNo)
              })
            } else {
              foundRow = value.data.find(item => item.JobOrdId === row.JobOrdId)
            }
            console.log("foundRow", foundRow)
            if (foundRow) {
              foundRow.IsAdded = true

              //     foundRow.driver = foundRow.driver || item.driver
              //     foundRow.driverMobile = foundRow.driverMobile || item.driverMobile
              //     foundRow.driverIdCardNo = foundRow.driverIdCardNo || item.driverIdCardNo
              //     foundRow.plateNo = foundRow.plateNo || item.plateNo
              //     foundRow.atd_eventTime = foundRow.atd_eventTime || item.TakeLevTm
              //     foundRow.ata_eventTime = foundRow.ata_eventTime || item.ArvTm

              row.driver = row.driver || foundRow.driver
              row.driverMobile = row.driverMobile || foundRow.driverMobile
              row.driverIdCardNo = row.driverIdCardNo || foundRow.driverIdCardNo
              row.plateNo = row.plateNo || foundRow.plateNo
              row.atd_eventTime = row.atd_eventTime || foundRow.TakeLevTm
              row.ata_eventTime = row.ata_eventTime || foundRow.ArvTm
              row.containerNo = row.containerNo || foundRow.ContNo
            }
          }
          // for (const item of value.data) {
          //   const foundRow = result.find(row => row.JobOrdId === item.JobOrdId)
          //   if (foundRow) {
          //     foundRow.driver = foundRow.driver || item.driver
          //     foundRow.driverMobile = foundRow.driverMobile || item.driverMobile
          //     foundRow.driverIdCardNo = foundRow.driverIdCardNo || item.driverIdCardNo
          //     foundRow.plateNo = foundRow.plateNo || item.plateNo
          //     foundRow.atd_eventTime = foundRow.atd_eventTime || item.TakeLevTm
          //     foundRow.ata_eventTime = foundRow.ata_eventTime || item.ArvTm
          //   }
          // }
          return result
        })
      }
    })
  }, [ax, selectionModel, filterData])

  const onDateTimeFinish = useCallback((value) => {
    if (!clickedDateTimeParams?.row) {
      return
    }
    setDataTable(o => {
      const result = [...o]
      if (selectionModel.includes(clickedDateTimeParams.row.id)) {
        for (const id of selectionModel) {
          const foundRow = result.find(row => row.id === id)
          if (foundRow) {
            foundRow[clickedDateTimeParams.field] = value
          }
        }
      } else {
        const foundRow = result.find(row => row.JobOrdId === clickedDateTimeParams.row.JobOrdId)
        if (foundRow) {
          foundRow[clickedDateTimeParams.field] = value
        }
      }
      return result
    })
  }, [selectionModel])

  const sendDispatchTruck = useCallback((row) => {
    let argsArr = []
    if (selectionModel.includes(row.id)) {
      argsArr = dataTable.filter(item => selectionModel.includes(item.id))
    } else {
      argsArr = [row]
    }
    const postData = {
      argsArr: argsArr,
      getArgs: lastFilter
    }
    ax.post(branchApiNm.sendDispatchTruck, postData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, 'JobOrdId'))
      }
    })
  }, [ax, dataTable, selectionModel])

  const sendMileStone = useCallback((colName, row, sequence) => {
    if (colName === "pod_eventTime") {
      selectedJobOrdId = row.JobOrdId
      selectedRow = row
      setDialogSelectImageOpen(true)
    } else {
      if (!row[colName]) {
        alertError("กรุณากรอกข้อมูล")
        return
      }
      const postData = {
        JobOrdId: row.JobOrdId,
        businessRefNo: row.businessRefNo,
        eventTime: row[colName],
        sequence: sequence,
        getArgs: lastFilter
      }
      ax.post(branchApiNm.sendMileStone, postData).then(value => {
        if (value.data) {
          setDataTable(addIdForDataGrid(value.data, 'JobOrdId'))
        }
      })
    }
  }, [ax])

  const handleViewImageClick = useCallback((params) => {
    if (params.row.PODFile) {
      const file = params.row.PODFile
      linkUrlImage = `/file/job-order/${file.JobOrdId}/${file.FileNm}`
      setDialogFullScreenImageOpen(true)
    }
  }, [])

  const pasteDataFromClipboard = useCallback(() => {

    if (selectionModel.length !== 1) {
      alertError("กรุณาเลือก 1 แถวเพื่อวางข้อมูล")
      return
    }

    navigator.clipboard.readText().then(text => {
      const rows = text.split("\n")
      const dataArr = []
      for (const row of rows) {
        const cols = row.split("\t")
        if (cols.length === 5) {
          dataArr.push({
            businessRefNo: cols[0],
            plateNo: cols[1],
            driver: cols[2],
            driverMobile: cols[3],
            driverIdCardNo: cols[4]
          })
        }
      }
      setDataTable(o => {
        const result = [...o]
        const foundIndex = result.findIndex(row => row.id === selectionModel[0])
        if (foundIndex !== -1) {
          for (let i = 0; i < dataArr.length; i++) {

            result[foundIndex + i] = {
              ...result[foundIndex + i],
              ...dataArr[i]
            }
          }
        }
        return result
      })
    })
  }, [selectionModel])

  const handleClearData = useCallback(() => {
    setDataTable(o => {
      const result = [...o]
      for (const id of selectionModel) {
        const foundRow = result.find(row => row.id === id)
        if (foundRow) {
          foundRow.driver = ''
          foundRow.driverMobile = ''
          foundRow.driverIdCardNo = ''
          foundRow.plateNo = ''
          foundRow.atd_eventTime = null
          foundRow.ata_eventTime = null
        }
      }
      return result
    })
  }, [selectionModel, ])

  const onDialogJOFinish = useCallback((data) => {
    if (data) {
      setDataTable(addIdForDataGrid(data, 'JobOrdId'))
    } else {
      getData(lastFilter)
    }
  }, [getData])

  const onSwapDataFinish = useCallback((jobOrdId) => {
    console.log("jobOrdId", jobOrdId)
    setDataTable(o => {
      const result = [...o]
      console.log("result::", result)
      const targetIndex = result.findIndex(row => row.id === +jobOrdId)
      const sourceIndex = result.findIndex(row => row.id === selectedJobOrdId)
      console.log("targetIndex", targetIndex)
      if (targetIndex < 0) {
        alertError("ไม่พบเลขที่ใบงาน")
        return o
      }

      console.log("targetIndex", targetIndex)
      console.log("sourceIndex", sourceIndex)
      const targetRow = { ...result[targetIndex] }
      console.log("selectedRow", selectedRow)
      console.log("targetRow", targetRow)
      result[targetIndex] = {
        ...selectedRow,
        id: targetRow.id,
        No: targetRow.No,
        JobOrdId: targetRow.JobOrdId,
        businessRefNo: targetRow.businessRefNo,
        AptTm: targetRow.AptTm,
        edit: "OK",
      }
      result[sourceIndex] = {
        ...targetRow,
        id: selectedRow.id,
        No: selectedRow.No,
        JobOrdId: selectedRow.JobOrdId,
        businessRefNo: selectedRow.businessRefNo,
        AptTm: selectedRow.AptTm,
      }

      console.log("result::", result)
      return result
    })
  }, [])
  const sendATD = useCallback((row) => { sendMileStone("atd_eventTime", row, 1) }, [sendMileStone])
  const sendATA = useCallback((row) => { sendMileStone("ata_eventTime", row, 2) }, [sendMileStone])
  const sendPOD = useCallback((row) => { sendMileStone("pod_eventTime", row, 3) }, [sendMileStone])

  const columnMemo = useMemo(() => columns({ handleViewImageClick, sendDispatchTruck, sendATD, sendATA, sendPOD })
    , [sendDispatchTruck, sendATD, sendATA, sendPOD, handleViewImageClick])

  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height='100%'>
      <BoxFR>
        <Button variant='contained' onClick={handleAddDriverFromJobOrder}>
          <CloudDownloadRounded sx={{ mr: 1 }} /> ดึงข้อมูลผู้รับผิดชอบใบงาน</Button>

        <Box sx={{ position: "relative" }}>
          <Button variant="contained" onClick={pasteDataFromClipboard}>
            <ContentPasteRounded sx={{ mr: 1 }} />
            วางข้อมูลจาก Clipboard
          </Button>
          <Typography sx={{ position: "absolute", bottom: -22, left: 0, width: 500 }} variant='caption'>(เลือกแถวและวางข้อมูลจาก Excel(JOB NO-&gt;บัตรปชช.) </Typography>
        </Box>
        <ClearButton label="ล้างข้อมูลแถวที่เลือก" onClick={handleClearData} />
        <Box flex={1} />
        <TextField {...textFieldProps("JobNo")} />
        <TextField {...textFieldProps("ContNo")} />
        <DateTimeSelect sx={{ width: 180 }} label='เวลาทำรายการ' start={filterData.AptTmSt} end={filterData.AptTmEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, AptTmSt: st, AptTmEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <SearchButton onClick={(() => getData(filterData))} />
        <ClearButton onClick={(() => setFilterData({ ...clearFilterData }))} />
      </BoxFR>
      <Box height='100%' >
        <DataGridCellExpand
          apiRef={apiRef}
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(ids) => { setSelectionModel(ids) }}
          hideFooter
          rows={dataTable}
          columns={columnMemo}
          // onRowDoubleClick={handleRowDoubleClick}
          onCellClick={handleCellClick}
          onCellEditCommit={(params) => { handleCellEdit(params) }}
          slotProps={{
            cell: {
              onFocus: (event) => {
                console.log(`Column: "${event.currentTarget.dataset.field}".`);
                console.log(`Row: "${event.currentTarget.parentElement.dataset.id}".`);
              },
              onMouseLeave: (event) => { console.log(event.currentTarget) }
            }
          }}
        />
      </Box>
      <DateTimeDialog
        oldValue={oldDateTimeValue}
        dialogOpen={dialogDateTimeOpen}
        setDialogOpen={setDialogDateTimeOpen}
        onFinish={onDateTimeFinish} />
      <DailyJobDialogV3
        dialogOpen={dialogJOOpen}
        setDialogOpen={setDialogJOOpen}
        selectedId={selectedJobOrdId}
        onFinish={onDialogJOFinish}
        lastFilter={null}
      />
      <SelectImageDialog
        dialogOpen={dialogSelectImageOpen}
        setDialogOpen={setDialogSelectImageOpen}
        jobOrdId={selectedJobOrdId}
        onFinish={onDialogJOFinish}
        lastFilter={lastFilter}
        businessRefNo={selectedRow?.businessRefNo}
      />
      <FullScreenImageDialog
        dialogOpen={dialogFullScreenImageOpen}
        setDialogOpen={setDialogFullScreenImageOpen}
        linkUrl={linkUrlImage}
      />
      <TextBoxDialog
        dialogOpen={dialogTextBoxOpen}
        setDialogOpen={setDialogTextBoxOpen}
        onFinish={onSwapDataFinish} />
      {/* <PettyCashDialog
        selectedId={selectedId}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        onFinish={onFinish} /> */}
    </BoxFC>
  );
}

export default BYDData