import { RemoveCircle } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { expenseColNm } from "constants/expenseConstant";
import { numberFormat } from "utils";

export const expeseJobColumns = (handleRemoveRow) => [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "ExpNm",
    headerName: "Col",
    flex: 1,
  },
  {
    field: "actions",
    type: "actions",
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<RemoveCircle />}
        label="Remove"
        onClick={() => handleRemoveRow(params.row)}
      />
    ]
  },
]

export const expensePOINoJobCol = (handleDeleteRow) => [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 50,
    align: 'center',
  },
  {
    field: 'IsCost',
    headerName: "ประเภท",
    width: 90,
    cellClassName: (params) => params.value === 1 ? 'isCost' : 'isExp',
    valueFormatter: (params) => params.value === 1 ? `ต้นทุน` : `รายได้`,
  },
  {
    field: 'IsOwnRcpt',
    headerName: expenseColNm.IsOwnRcpt,
    width: 100,
    valueFormatter: (params) => {
      return params.value === 1 ? `ใบเสร็จบริษัท` : `ใบเสร็จลูกค้า`;
    },
  },
  {
    field: 'ExpNm',
    headerName: expenseColNm.ExpNm,
    width: 150,
  },
  {
    field: 'POISNm',
    headerName: "สถานที่",
    width: 100,
    align: 'center'
  },
  {
    field: 'ContSize',
    headerName: "ขนาดตู้",
    width: 70,
    align: 'center'
  },
  {
    field: 'ContTyp',
    headerName: "ประเภทตู้",
    width: 70,
    align: 'center'
  },
  {
    field: 'ShpmTypNm',
    headerName: "ประเภทงาน",
    width: 100,
    align: 'center'
  },
  {
    field: 'VatPrct',
    headerName: expenseColNm.VatPrct,
    type: 'number',
    width: 80,
    align: 'right',
    editable: true,
    valueFormatter: (params) => {
      return `${params.value}%`;
    }
  },
  {
    field: 'WhtPrct',
    headerName: expenseColNm.WhtPrct,
    width: 80,
    type: 'number',
    align: 'right',
    editable: true,
    valueFormatter: (params) => {
      return `${params.value}%`;
    }
  },
  {
    field: 'UntPr',
    headerName: expenseColNm.UntPr,
    width: 90,
    type: 'number',
    align: 'right',
    editable: true,
    valueFormatter: ({ value }) => value ? numberFormat(value) : "-",
  },
  {
    field: 'Prty',
    headerName: expenseColNm.Prty,
    width: 60,
    type: 'number',
    align: 'center',
    editable: true,
  },
  {
    field: 'Rmk',
    headerName: expenseColNm.Rmk,
    flex: 1,
    editable: true,
  },
  {
    field: "actions",
    type: "actions",
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<RemoveCircle />}
        label="Delete"
        onClick={() => handleDeleteRow(params.row)}
      />
    ]
  },
]

export const expenseJobNoJobCol = (handleDeleteRow) => [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 70,
    align: 'center',
  },
  {
    field: 'IsOwnRcpt',
    headerName: expenseColNm.IsOwnRcpt,
    width: 100,
    valueFormatter: (params) => {
      return params.value === 1 ? `ใบเสร็จบริษัท` : `ใบเสร็จลูกค้า`;
    },
  },
  {
    field: 'ExpNm',
    headerName: expenseColNm.ExpNm,
    width: 150,
  },
  {
    field: 'ContSize',
    headerName: "ขนาดตู้",
    width: 70,
    align: 'center'
  },
  {
    field: 'ContTyp',
    headerName: "ประเภทตู้",
    width: 70,
    align: 'center'
  },
  {
    field: 'TlrTyp',
    headerName: "ประเภทหาง",
    width: 70,
    align: 'center'
  },
  {
    field: 'ShpmTypNm',
    headerName: "ประเภทงาน",
    width: 70,
    align: 'center'
  },
  {
    field: 'LocPOINm',
    headerName: "สถานที่",
    width: 100,
    align: 'center'
  },
  {
    field: 'VatPrct',
    headerName: expenseColNm.VatPrct,
    width: 60,
    align: 'right',
    valueFormatter: (params) => {
      return `${params.value}%`;
    }
  },
  {
    field: 'WhtPrct',
    headerName: expenseColNm.WhtPrct,
    width: 60,
    align: 'right',
    valueFormatter: (params) => {
      return `${params.value}%`;
    }
  },
  {
    field: 'UntPr',
    headerName: expenseColNm.UntPr,
    width: 90,
    type: 'number',
    align: 'right',
    editable: true,
    valueFormatter: ({ value }) => value ? numberFormat(value) : "-",
  },
  {
    field: 'Prty',
    headerName: expenseColNm.Prty,
    width: 70,
    type: 'number',
    align: 'center',
    editable: true,
  },
  {
    field: 'Rmk',
    headerName: expenseColNm.Rmk,
    flex: 1,
    editable: true,
  },
  {
    field: "actions",
    type: "actions",
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<RemoveCircle />}
        label="Delete"
        onClick={() => handleDeleteRow(params.row)}
      />
    ]
  },
]