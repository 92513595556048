export const expenseApiNm = {
  getExpense: "/expense/getExpense",
  insertExpense: "/expense/insertExpense",
  updateExpense: "/expense/updateExpense",
  deleteExpense: "/expense/deleteExpense"
}


export const expenseColNm =  {
  ExpId: "ชื่อค่าใช้จ่าย",
  ExpNm: "ชื่อค่าใช้จ่าย",
  ExpNmEn: "ชื่อค่าใช้จ่าย (Invice)",
  ExpTypId: "ประเภทค่าใช้จ่าย",
  ExpTypNm: "ประเภทค่าใช้จ่าย",
  VatPrct: "Vat. (%)",
  WhtPrct: "หัก ณ ที่จ่าย (%)",
  UntPr: "ราคา",
  Prty: "Priority",
  IsCost: "รายได้/ต้นทุน",
  IsOwnRcpt: "ประเภทใบเสร็จ",
  Rmk: "หมายเหตุ",
  ModDte: "วันที่แก้ไข",
  ModAcc: "ผู้แก้ไข",
  XpssStkCod: "รหัสสินค้า Express ขาย",
  XpssStkCodBuy: "รหัสสินค้า Express ซื้อ (รายได้)",
  XpssStkCodBuyOR: "รหัสสินค้า Express ซื้อ (ต้นทุน)",
  IsOprShow: "แสดงในค่าใช้จ่ายหน้างาน"
}