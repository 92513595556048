export const columns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "FLNNm",
    headerName: "ชื่อ-สกุล",
    width: 160,
  },
  {
    field: "TukCd",
    headerName: "เลขรถ",
    width: 100,
  },
  
]

export const endColumns = [
  // {
  //   field: "Total",
  //   headerName: "รวม",
  //   width: 120,
  //   align: "left",
  //   headerAlign: "left",
  // },
  {
    field: "NumSts1",
    headerName: "มา",
    width: 50,
    align: "center",
    headerAlign: "left",
    valueFormatter: ({ value }) => value || "0",
  },
  {
    field: "NumSts10",
    headerName: "สาย",
    width: 50,
    align: "center",
    headerAlign: "left",
    valueFormatter: ({ value }) => value || "0",
  },
  {
    field: "NumSts101",
    headerName: "ลากิจ",
    width: 50,
    align: "center",
    headerAlign: "left",
    valueFormatter: ({ value }) => value || "0",
  },
  {
    field: "NumSts102",
    headerName: "ป่วย",
    width: 50,
    align: "center",
    headerAlign: "left",
    valueFormatter: ({ value }) => value || "0",
  },
  {
    field: "NumSts103",
    headerName: "พักร้อน",
    width: 50,
    align: "center",
    headerAlign: "left",
    valueFormatter: ({ value }) => value || "0",
  },
  {
    field: "NumSts104",
    headerName: "ขาด",
    width: 50,
    align: "center",
    headerAlign: "left",
    valueFormatter: ({ value }) => value || "0",
  },
  {
    field: "TotalAbsent",
    headerName: "รวมไม่มา",
    width: 60,
    align: "center",
    headerAlign: "left",
    valueGetter: ({row})=> {
      const absent = row.NumSts101 + row.NumSts102 + row.NumSts103 + row.NumSts104
      return `${absent}/${row.NumSts1 + row.NumSts10 + absent}`
    }
  },
  {
    field: "NumOverTemp",
    headerName: "Tempเกิน",
    width: 70,
    align: "center",
    headerAlign: "left",
    valueFormatter: ({ value }) => `${value}`,
    cellClassName: ({ value }) => value > 0 ? "temp-over" : ""
  },
  {
    field: "NumTemp",
    headerName: "Temp(ครั้ง)",
    width: 70,
    align: "center",
    headerAlign: "left",
    valueFormatter: ({ value }) => `${value}`,
  },
  {
    field: "NumOverAlc",
    headerName: "Alcเกิน",
    width: 60,
    align: "center",
    headerAlign: "left",
    valueFormatter: ({ value }) => `${value}`,
    cellClassName: ({ value }) => value > 0 ? "alcohol-over" : ""
  },
  {
    field: "NumAlc",
    headerName: "Alc(ครั้ง)",
    width: 60,
    align: "center",
    headerAlign: "left",
    valueFormatter: ({ value }) => `${value}`,
  }]