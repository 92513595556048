import { Box, Dialog, DialogContent } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import AddButton from "components/buttons/AddButton"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { expeseJobColumns } from "./columns"
import ComboBox from "components/ComboBox"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"

const initDialogData = {
  JobId: null,
  ExpId: null,
}

const ExpenseJobDefaultDialog = ({ jobId, dialogOpen, setDialogOpen, onFinish }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initDialogData })
  const [dataTable, setDataTable] = useState([])

  const handleRemoveRow = useCallback((row) => {
    setDataTable(o => {
      const result = o.filter(item => item.id !== row.id)
      return result.map((item, index) => ({ ...item, No: index + 1 }))
    })
  }, [])

  const handleAdd = useCallback(() => {
    setDataTable(o => {
      if (o.find(row => row.id === dialogData.ExpId)) {
        return o
      }
      return [
        ...o, {
          No: o.length + 1,
          id: dialogData.ExpId,
          ExpNm: msData.expObj[dialogData.ExpId].ExpNm,
        }
      ]
    })
  }, [dialogData.ExpId, msData.expObj])

  const expeseJobColumnsMemo = useMemo(() => expeseJobColumns(handleRemoveRow), [handleRemoveRow])

  useEffect(() => {
    if (dialogOpen) {
    }
  }, [dialogOpen, ax])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="กำหนดค่าใช้จ่ายประจำ"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} >
          <BoxFR>
            <ComboBox sx={{ flex: 1 }} options={msData.expOprCombo} label="ชื่อค่าใช้จ่าย"
              selectedId={dialogData.ExpId}
              setSelectedId={(id) => setDialogData(o => ({ ...o, ExpId: id }))}
            />
            <AddButton onClick={handleAdd}>เพิ่ม</AddButton>
          </BoxFR>
          <Box height={200}>
            <DataGridCellExpand
              hideFooter
              hideToolbar
              rows={dataTable}
              columns={expeseJobColumnsMemo}
            />
          </Box>

        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(ExpenseJobDefaultDialog)