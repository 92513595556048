import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { initFilterData } from "./initData";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import SearchButton from "components/buttons/SearchButton";
import ClearButton from "components/buttons/ClearButton";
import ComboBox from "components/ComboBox";
import DateTimeSelect from "components/DateTimeSelect";
import { branchApiNm } from "../ExtraMainLayoutConstant";
import { addIdForDataGrid } from "utils";
import { addSumRow, sxSumColorClass } from "utils/highlightSumColor";
import { columns } from "./columns";
import { Box, Typography } from "@mui/material";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { blue } from "@mui/material/colors";

const TruckIncomeSummaryKN = () => {
  const { ax, msData } = useContext(GlobalStateContext)

  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [jobCombo, setJobCombo] = useState(msData.jobCombo)
  const [dataTable, setDataTable] = useState([])

  const modDataTable = useMemo(() => {
    const result = addSumRow({
      dataTable: dataTable,
      sumColNms: ["NumJob", "OwnRcptAmnt", "CusRcptAmnt"],
      sumColTotal: "TukCd"
    })
    const sumRow = { ...result.find(row => row.id === 0) }
    sumRow.id = -1
    return [
      sumRow,
      ...result
    ]
  }, [dataTable])

  const sumData = useMemo(() => {
    let all = 0;
    let sts1 = 0;
    let stsNot1 = 0;
    let hasJob = 0;
    let noJob = 0;
    let sumNumJobOrd = 0;
    let allDrv = 0;
    let drvAbsent = 0;
    let drvPresent = 0;
    for (const row of dataTable) {
      if (row.id > 0) {
        if (row.DrvId) {
          allDrv++
        }
        
        if (row.DrvId) {
          if ((row.DrvAtnStsId && row.DrvAttnStsId < 100) || row.NumJob > 0) {
            drvPresent++
          } else if(row.DrvAttnStsId && row.DrvAttnStsId >= 100) {
            drvAbsent++
          }
        }
        all++
        sumNumJobOrd += +row.NumJob
        if (row.TukAttnStsId === 1) {
          sts1++
        } else {
          stsNot1++
        }
        if (row.NumJob > 0) {
          hasJob++
        } else {
          noJob++
        }
      }
    }

    return {
      all: all,
      sts1: sts1,
      stsNot1: stsNot1,
      hasJob: hasJob,
      noJob: noJob,
      allDrv: allDrv,
      drvAbsent: drvAbsent,
      drvPresent: drvPresent,
      sumNumJobOrd: sumNumJobOrd,
      avgNumJobOrd: drvPresent > 0 ? (sumNumJobOrd / drvPresent).toFixed(2) : 0
    }
  }, [dataTable])

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getTruckIncomeSummary, filter).then(value => {
      // setDataTable(addIdForDataGrid(value.data, "TukID"))
      const result = value.data.map((tuk) => {
        for (let i = 0; i < tuk.NumJob; i++) {
          if ([0, 1].includes(i)) {
            tuk.JobOrders[i].NumJob = 1
            tuk[`JobOrd${i + 1}`] = tuk.JobOrders[i]
          } else {
            if (tuk.NumJob === 3) {
              tuk.JobOrders[2].NumJob = 1
              tuk.JobOrd3 = tuk.JobOrders[2]
            } else {

              let sumOwnRcptAmnt = 0;
              let sumCusRcptAmnt = 0;
              const jobOrders = []
              for (let j = 2; j < tuk.NumJob; j++) {
                const jobOrder = tuk.JobOrders[j]
                sumOwnRcptAmnt += +jobOrder.OwnRcptAmnt
                sumCusRcptAmnt += +jobOrder.CusRcptAmnt
                jobOrders.push(jobOrder)
              }
              tuk.JobOrd3 = {
                NumJob: tuk.NumJob - 2,
                OwnRcptAmnt: sumOwnRcptAmnt,
                CusRcptAmnt: sumCusRcptAmnt,
                Total: sumOwnRcptAmnt + sumCusRcptAmnt,
                JobOrders: jobOrders
              }
            }
            break;
          }
        }
        return tuk
      })
      console.log("result", result)
      setDataTable(addIdForDataGrid(result, "TukId"))
    })
  }, [ax])

  const setSelectDate = useCallback((start, end) => {
    setFilterData(o => ({
      ...o,
      AptTmSt: start,
      AptTmEn: end
    }))
  }, [])

  const handleCusComboChange = useCallback((id) => {
    setFilterData(o => ({ ...o, CusId: id, JobId: null }))
    if (!id) {
      setJobCombo(msData.jobCombo)
    } else {
      setJobCombo(msData.jobCombo.filter(job => job.refId === id))
    }
  }, [msData.jobCombo])

  const handleSearchButtonClick = useCallback(() => {
    console.log("filterData", filterData)
    getData(filterData)
  }, [getData, filterData])

  const handleClearButtonClick = useCallback(() => { }, [])

  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  console.log("dataTable", dataTable)
  return (
    <BoxFC height="100%">
      <BoxFR width="100%" sx={{ justifyContent: "center" }}>
        <DateTimeSelect
          sx={{ width: 180 }}
          label="วันที่วิ่งงาน"
          start={filterData.AptTmSt}
          end={filterData.AptTmEn}
          setSelectDate={setSelectDate}
          getData={handleSearchButtonClick}
        />
        <ComboBox sx={{ width: 180 }} options={msData.cusCombo} label="ลูกค้า"
          selectedId={filterData.CusId}
          setSelectedId={handleCusComboChange}
        />
        <ComboBox sx={{ width: 180 }} options={jobCombo} label="งาน"
          selectedId={filterData.JobId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))}
        />
        <SearchButton onClick={handleSearchButtonClick} />
        <ClearButton onClick={() => handleClearButtonClick()} />
      </BoxFR>
      <BoxFC sx={{ gap: 0 }}>
        <Typography fontSize="1.2rem"><strong>รถทั้งหมด:</strong>{sumData.all} &nbsp;&nbsp;<strong>วิ่งงาน:</strong>{sumData.hasJob} &nbsp;&nbsp;<strong>ไม่วิ่งงาน:</strong>{sumData.noJob} &nbsp;&nbsp;<strong>ระหว่างซ่อม:</strong>{sumData.stsNot1}</Typography>
        <Typography fontSize="1.2rem"><strong>พนักงานขับรถ:</strong>{sumData.allDrv} &nbsp;&nbsp;<strong>วิ่งงาน:</strong>{sumData.drvPresent} &nbsp;&nbsp;<strong>ลา:</strong>{sumData.drvAbsent} </Typography>
        <Typography fontSize="1.2rem"><strong>จำนวนงานทั้งหมด:</strong>{sumData.sumNumJobOrd} &nbsp;&nbsp;<strong>เฉลี่ยงานต่อคัน:</strong>{sumData.avgNumJobOrd} <Typography variant="caption" fontWeight="normal">(เฉพาะรถที่มีคนขับและวิ่งงาน)</Typography></Typography>
      </BoxFC>
      <Box flex={1} sx={sxSumColorClass}>
        <DataGridCellExpand
          density="compact"
          hideFooter
          disableSelectionOnClick
          rows={modDataTable}
          columns={columns}
          getRowClassName={(params) => [-1, 0].includes(params.id) && "total"}
        />
      </Box>
      <Typography variant="caption" sx={{ color: blue[700] }}>
        <strong>หมายเหตุ: </strong>C=Charge, A=Advance, T=Total
      </Typography>
    </BoxFC>
  );
}

export default TruckIncomeSummaryKN;