export const initDialogData = {
  ExpJobId: 0,
  IsCost: 0,
  JobId: null,
  CusId: null,
  ExpId: null,
  POIId: null,
  ContSize: "",
  ContTyp: "",
  TlrTyp: "",
  TlrTypId: null,
  ShpmTypId: null,
  VatPrct: 0,
  WhtPrct: 0,
  UntPr: 0,
  Prty: 100,
  IsOwnRcpt:1,
  IsOprHide: 0,
  Rmk: ""
}

export const initFilterData = {
  CusId: null,
  JobId: null,
  ExpId: null,
}