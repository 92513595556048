

export const driverApiNm = {
  getDriver: "/driver/getDriver",
  getDriverPerformance: "/driver/getDriverPerformance", 
  getDPNumAll: "/driver/getDPNumAll", 
  getDPGroupByDate: "/driver/getDPGroupByDate", 
  getDPGroupByDay: "/driver/getDPGroupByDay", 
  getDPGroupByHourAptTm: "/driver/getDPGroupByHourAptTm", 
  getDPGroupByHourArvTm: "/driver/getDPGroupByHourArvTm", 
  getDPGroupByJob: "/driver/getDPGroupByJob", 
  getDPTotalIncome: "/driver/getDPTotalIncome",
  getDPDriverIncome: "/driver/getDPDriverIncome",
  insertDriver: "/driver/insertDriver",
  updateDriver: "/driver/updateDriver",
  updateDriverColumn: "/driver/updateDriverColumn",
  uploadImage: "/driver/uploadImage",
  updaetDriverTag: "/driver/updaetDriverTag",
  deleteDriver: "/driver/deleteDriver", 
}

export const driverColNm =  {
  DrvId: "รหัสพนักงาน",
  DrvCd: "รหัสพนักงาน",
  DrvNm: "ชื่อพนักงานขับรถ",
  FName: "ชื่อ",
  LName: "นามสกุล",
  NName: "ชื่อเล่น",
  Tel: "โทร.",
  Addr: "ที่อยู่",
  CitzId: "เลขบัตรประชาชน",
  LicTyp: "ประเภทใบขับขี่",
  IsActv: "Active",
  IsSub: "Subcontract",
  WrkDteSt: "วันที่เริ่มทำงาน",
  TukId: "รหัสรถ",
  TukCd: "เลขรถ",
  Rmk: "หมายเหตุ",
  PfImg: "รูปโปรไฟล์",
  CitzImg: "รูปบัตรประชาชน",
  DrvLicImg: "รูปใบขับขี่",
  ModDte: "แก้ไขล่าสุด",
  ModAccId: "ผู้แก้ไขล่าสุด",
  Tags: "Tag",
  Tag: "Tag",
  BkAccNo: "เลขบัญชีธนาคาร",
}


export const initDialogData = {
  DrvId: 0,
  FName: "",
  LName: "",
  NName: "",
  Tel: "",
  Addr: "",
  CitzId: "",
  LicTyp: 1,
  IsActv: 1,
  IsSub: 0,
  WrkDteSt: null,
  WrkDteEn: null,
  TukId: null,
  TukCd: "",
  Rmk: "",
  PfImg: "",
  CitzImg: "",
  DrvLicImg: "",
  BkAccNo: "",
  ModDte: null,
  ModAccId: null,
  pfImgFile: null,
  citzImgFile: null,
  drvLicImgFile: null,
  pfImgPv: null,
  citzImgPv: null,
  drvLicImgPv: null,
}

export const initFilterData = {
  Name: null,
  IsSub: 0,
  IsActv: 1,
}

export const initComboData = {
  tukDialogValue: null,
  tukDialogText: "",
}

export const act = {
  ON_CHANGE_FILTER: "ON_CHANGE_FILTER",
  ON_CHANGE_DIALOG_DATA: "ON_CHANGE_DIALOG_DATA",
  ON_CHANGE_DIALOG_DATA_IMG: "ON_CHANGE_DIALOG_DATA_IMG",
  ON_CHANGE_DIALOG_COMBO: "ON_CHANGE_DIALOG_COMBO",
  CLEAR_FILTER: "FILTER_CLEAR",
  SET_DATA_TABALE: "SET_DATA_TABALE",
  SET_DIALOG_OPEN: "SET_DIALOG_OPEN",
  SET_DIALOG_DATA: "SET_DIALOG_DATA"
}