import { Box, Button, IconButton, Popover, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { BoxFC, BoxFR } from "components/BoxCustom";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { columns, endColumns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { CheckCircleRounded, CheckRounded, CloseRounded, DoNotDisturbOnRounded } from "@mui/icons-material";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { driverAttendApiNm } from "./constant";
import AbsentChoice from "./AbsentChoice";
import { blue, green, purple, red, yellow } from "@mui/material/colors";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import { alertWarning } from "components/Alert";
import { useGridApiRef } from "@mui/x-data-grid-pro";

const initFilterData = { DrvAttnMonth: dayjs() }

let absentChoiceData = {
  defaultSts: 1,
  defaultDscp: "",
  drvAttnSts: [],
  drvId: null,
  col: "",
}

let lastFilterData = { ...initFilterData }



const DriverAttend = () => {

  const [drvAttnSts, setDrvAttnSts] = useState([])
  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [selectionModel, setSelectionModel] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [dateAddData, setDateAddData] = useState(dayjs())

  const { ax } = useContext(GlobalStateContext)
  const apiRef = useGridApiRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const addDataFromDateProp = useMemo(() => ({
    label: "วันที่",
    inputFormat: "DD/MM/YYYY",
    mask: "__/__/____",
    value: dateAddData,
    onChange: (newValue) => { setDateAddData(newValue) },
    renderInput: (params) => <TextField size="small"  {...params} sx={{ width: 150 }} />,
  }), [dateAddData])

  const processData = useCallback((data, filter) => {

    const lastDay = filter.DrvAttnMonth.endOf("month").date()
    const sumDay = {}
    for (let i = 1; i <= lastDay; i++) {
      sumDay[`d${i}`] = drvAttnSts.reduce((prev, cur) => ({ ...prev, [cur.DrvAttnStsId]: 0 }), {})
    }

    const stsTxt = drvAttnSts.reduce((prev, cur) => ({ ...prev, [cur.DrvAttnStsId]: cur.Abbr }), {})
    const result = []
    let no = 0;
    const stsGranTotal = drvAttnSts.reduce((prev, cur) => ({ ...prev, [cur.DrvAttnStsId]: 0 }), {})
    let sumOverTemp = 0;
    let sumOverAlc = 0;
    let sumNumTemp = 0;
    let sumNumAlc = 0;
    let sumSts1 = 0;
    let sumSts10 = 0;
    let sumSts101 = 0;
    let sumSts102 = 0;
    let sumSts103 = 0;
    let sumSts104 = 0;
    for (const row of data) {
      no++
      const newRow = {
        id: row.DrvId,
        No: no,
        DrvId: row.DrvId,
        FLNNm: row.FLNNm,
        TukCd: row.TukCd,
      }
      let sumAbsent = 0;
      let allDay = 0;
      let numOverTemp = 0;
      let numOverAlc = 0;
      let numTemp = 0;
      let numAlc = 0;
      let numSts1 = 0;
      let numSts10 = 0;
      let numSts101 = 0;
      let numSts102 = 0;
      let numSts103 = 0;
      let numSts104 = 0;

      const stsTotal = drvAttnSts.reduce((prev, cur) => ({ ...prev, [cur.DrvAttnStsId]: 0 }), {})
      for (const attn of row.Attns) {
        newRow[`d${attn.Dte}`] = attn.StsId ? `${attn.StsId};${stsTxt[attn.StsId]};${attn.Dscp ?? ""};${attn.Temp ?? ""};${attn.Alc ?? ""}` : null
        newRow[`d${attn.Dte}Obj`] = attn
        stsTotal[attn.StsId]++
        stsGranTotal[attn.StsId]++
        if (attn.StsId > 2) sumAbsent++
        attn.StsId&&allDay++
        sumDay[`d${attn.Dte}`][attn.StsId]++
        if (+attn.Temp > 37.7) numOverTemp++
        if (+attn.Alc > 0) numOverAlc++
        if (attn.Temp !== null) numTemp++
        if (attn.Alc !== null) numAlc++

        if (attn.StsId !== null) {
          if (attn.StsId === 10) numSts10++
          else if (attn.StsId === 101) numSts101++
          else if (attn.StsId === 102) numSts102++
          else if (attn.StsId === 103) numSts103++
          else if (attn.StsId === 104) numSts104++
          else numSts1++
        }
      }
      sumOverTemp += numOverTemp
      sumOverAlc += numOverAlc
      sumNumTemp += numTemp
      sumNumAlc += numAlc
      sumSts1 += numSts1
      sumSts10 += numSts10
      sumSts101 += numSts101
      sumSts102 += numSts102
      sumSts103 += numSts103
      sumSts104 += numSts104

      newRow.stsTotal = stsTotal
      newRow.Total = drvAttnSts
        .filter(sts => stsTotal[sts.DrvAttnStsId] > 0)
        .map(sts => `${stsTxt[sts.DrvAttnStsId]}:${stsTotal[sts.DrvAttnStsId]}`).join(", ")
      newRow.TotalAbsent = `${sumAbsent}/${allDay}`
      newRow.NumOverTemp = numOverTemp
      newRow.NumOverAlc = numOverAlc
      newRow.NumTemp = numTemp
      newRow.NumAlc = numAlc
      newRow.NumSts1 = numSts1
      newRow.NumSts10 = numSts10
      newRow.NumSts101 = numSts101
      newRow.NumSts102 = numSts102
      newRow.NumSts103 = numSts103
      newRow.NumSts104 = numSts104
      result.push(newRow)
    }
    console.log("sumDay", sumDay)
    const sumDayText = { ...sumDay }
    for (const key in sumDayText) {
      sumDayText[key] = drvAttnSts
        .filter(sts => sumDay[key][sts.DrvAttnStsId] > 0)
        .map(sts => `${stsTxt[sts.DrvAttnStsId]}:${sumDay[key][sts.DrvAttnStsId]}`).join(", ")
    }
    const totalRow = {
      id: 0,
      no: "",
      FLNNm: "",
      TukCd: "รวม",
      NumOverTemp: sumOverTemp,
      NumOverAlc: sumOverAlc,
      NumTemp: sumNumTemp,
      NumAlc: sumNumAlc,
      NumSts1: sumSts1,
      NumSts10: sumSts10,
      NumSts101: sumSts101,
      NumSts102: sumSts102,
      NumSts103: sumSts103,
      NumSts104: sumSts104,
      ...sumDayText,
    }

    result.push(totalRow)
    return result
  }, [drvAttnSts])

  const getData = useCallback((filter) => {
    const postData = {
      DrvAttnDteSt: filter.DrvAttnMonth.startOf("month").format("YYYY-MM-DD"),
      DrvAttnDteEn: filter.DrvAttnMonth.endOf("month").format("YYYY-MM-DD"),
    }
    ax.post(driverAttendApiNm.getDriverAttendTable, postData).then(value => {
      if (value.data) {
        setDataTable(processData(value.data, filter))
        lastFilterData = postData
      }
    })
  }, [ax, processData])

  const handleCheck = useCallback((drvId, col, drvAttnStsId, dscp, temp, alc, isFromPopOver) => {
    // if (temp == null && alc == null && selectionModel.length > 1) {
    //   alertWarning("เลือกพนักงานขับรถหลายคน การเพิ่ม อุณหภูมิ และ Alcohol จะเพิ่มเฉพาะพนักงานที่เลือกเท่านั้น")
    // }
    console.log("drvId::", drvId)
    const drvIds = isFromPopOver ? [drvId] : selectionModel.length === 0 ? [drvId] : [...selectionModel.filter(id => id > 0), drvId]
    const postData = {
      argsArr: drvIds.map(drvId => ({
        DrvId: drvId,
        DrvAttnDte: filterData.DrvAttnMonth.format(`YYYY-MM-${col.replace("d", "").padStart(2, "0")}`),
        DrvAttnStsId: drvAttnStsId,
        Dscp: dscp,
        Temp: temp,
        Alc: alc
      })),
      getArgs: lastFilterData
    }
    ax.post(driverAttendApiNm.insertDuplicateUpdateBulkDriverAttend, postData).then(value => {
      if (value.data) {
        setDataTable(processData(value.data, filterData))
        setAnchorEl(null)
      }
    })
  }, [ax, filterData, selectionModel, processData])

  const handleOpenAbsentChoice = useCallback((params, attns) => (e) => {
    console.log("attns", attns)
    absentChoiceData = {
      defaultSts: attns && attns.length > 0 ? attns[0] : 101,
      defaultDscp: attns && attns.length > 0 ? attns[2] || "" : "",
      defaultTemp: attns && attns.length > 0 ? attns[3] || null : null,
      defaultAlc: attns && attns.length > 0 ? attns[4] || null : null,
      drvAttnSts: drvAttnSts,
      drvId: params.row.DrvId,
      col: params.field,
    }
    setAnchorEl(e.currentTarget)
  }, [drvAttnSts])

  const monthColumns = useMemo(() => {
    const lastDay = filterData.DrvAttnMonth.endOf("month").date()
    console.log("lastDay", lastDay)
    const modColumns = [...columns];
    const isSameMonth = filterData.DrvAttnMonth.month() === dayjs().month()
    for (let i = 1; i <= lastDay; i++) {
      modColumns.push({
        field: `d${i}`,
        headerName: `${i}`,
        width: 120,
        align: "center",
        headerAlign: 'center',
        cellClassName: (params) => {
          if (params.row[`d${i}Obj`]?.Alc > 0) {
            return "alcohol-over"
          } else if (params.row[`d${i}Obj`]?.Temp > 37.7) {
            return "temp-over"

          } else if (i === dayjs().date() && isSameMonth) {
            return "today"
          } else {
            return ""
          }
        },
        renderCell: (params) => {
          const attns = params.value ? params.value.split(";") : []
          return (
            params.id === 0 ? params.value :
              params.value ?
                <BoxFC sx={{ gap: 0, alignItems: "center" }}>
                  <IconButton sx={{ py: 0 }} onClick={handleOpenAbsentChoice(params, attns)}>

                    {["1", "2", "3", "10"].includes(attns[0]) ?
                      <CheckCircleRounded sx={{ color: ["1", "2", "3"].includes(attns[0]) ? green[700] : yellow[700] }} />
                      : <DoNotDisturbOnRounded color="error" />}

                  </IconButton>
                  <Typography variant="caption">
                    {attns[0] === "1" ? "" : `(${attns[1]})`}
                    {attns[2] ? attns[2] : null}
                    {[1, 10].includes(+attns[0]) && (attns[3] !== '' || attns[4] !== '') ? `(${attns[3] !== '' ? `${attns[3]}ºC` : ""} ${attns[4] !== '' ? `${attns[4]}Mg%` : ""})` : null}
                  </Typography>
                </BoxFC>

                :
                <BoxFR sx={{ gap: 0 }}>
                  <IconButton onClick={() => handleCheck(params.row.DrvId, params.field, 1)}>
                    <CheckRounded color="success" />
                  </IconButton>

                  <IconButton onClick={handleOpenAbsentChoice(params, attns)}>
                    <CloseRounded color="action" />
                  </IconButton>
                </BoxFR>
          )
        }
      });
    }
    modColumns.push(...endColumns)
    
    return modColumns
  }, [filterData.DrvAttnMonth, handleCheck, handleOpenAbsentChoice]);

  const handleDatePickerKeyUp = useCallback((e) => {
    if (e.key === "ArrowUp") {
      setFilterData(o => {
        const newData = { ...o, DrvAttnMonth: o.DrvAttnMonth.add(-1, "month") }
        getData(newData)
        return newData
      })
    } else if (e.key === "ArrowDown") {
      setFilterData(o => {
        const newData = { ...o, DrvAttnMonth: o.DrvAttnMonth.add(1, "month") }
        getData(newData)
        return newData
      })
    }
  }, [getData])

  const handleDatePickerChange = useCallback((newValue) => {
    setFilterData(o => {
      const newFilter = { ...o, DrvAttnMonth: newValue }
      getData(newFilter)
      return newFilter
    })
  }, [getData])

  const handleAddDataFromJobOrder = useCallback(() => {
    const postData = {
      AptTmSt: dateAddData.format("YYYY-MM-DD 00:00:00"),
      AptTmEn: dateAddData.format("YYYY-MM-DD 23:59:59"),
      getArgs: lastFilterData
    }
    ax.post(driverAttendApiNm.insertDriverAttendFromJobOrder, postData).then(value => {
      if (value.data) {
        setDataTable(processData(value.data.data))
        if (value.data.message) {
          alertWarning(value.data.message)
        }
      }
    })
  }, [ax, dateAddData, processData])

  useEffect(() => {
    getData({ ...initFilterData }, true)
  }, [getData])

  useEffect(() => {
    ax.post(driverAttendApiNm.getDriverAttendStatus).then(value => {
      if (value.data) {
        setDrvAttnSts(value.data)
      }
    })
  }, [ax])

  useEffect(() => {
    //monthColumns.length > 20 means already loaded
    if (monthColumns.length > 20 && apiRef.current) {

      apiRef.current.scrollToIndexes({ rowIndex: 0, colIndex: dayjs().date() + 3 })
    }
  }, [monthColumns, apiRef])
  console.log("dataTable", dataTable)
  return (
    <BoxFC height="100%" alignItems="center" sx={{ position: "relative" }}>
      <BoxFR sx={{ position: "absolute", top: 0, left: 0 }}>
        <Typography>เพิ่มข้อมูลจากงานประจำวัน</Typography>
        <DesktopDatePicker {...addDataFromDateProp} />
        <Button variant="contained" onClick={handleAddDataFromJobOrder}>
          เพิ่มข้อมูล
        </Button>
      </BoxFR>
      <DesktopDatePicker label="เดือน" value={filterData.DrvAttnMonth}
        openTo="month"
        views={["year", "month"]}
        renderInput={(params) => <TextField {...params} sx={{ width: 200 }} size="small" onKeyUp={handleDatePickerKeyUp} />}
        onChange={handleDatePickerChange} />
      <Box flex={1} width="100%" sx={{
        "& .MuiDataGrid-row:hover": {
          "& .today": { bgcolor: blue[100] },
          "& .temp-over": { bgcolor: purple[200] },
          "& .alcohol-over": { bgcolor: red[200] },
        },
        "& .today": { bgcolor: blue[50] },
        "& .total": { bgcolor: yellow[100] },
        "& .temp-over": { bgcolor: purple[100] },
        "& .alcohol-over": { bgcolor: red[100] },
      }}>
        <DataGridCellExpand
          //density="compact"
          apiRef={apiRef}
          checkboxSelection
          disableSelectionOnClick={true}
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          rowHeight={60}
          hideFooter
          rows={dataTable}
          columns={monthColumns}
          initialState={{
            pinnedColumns:
            {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'No', "FLNNm", "TukCd"],
              right: ["NumSts1", "NumSts10", "NumSts101", "NumSts102", "NumSts104", "NumSts103", "TotalAbsent", "NumOverTemp", "NumTemp", "NumOverAlc", "NumAlc"]
            }
          }}
          getRowClassName={(params) => params.id === 0 ? "total" : ""}
        //extraContext={[{ icon: <EditRounded />, label: "แก้ไข", onClick: handleEditData }]}
        />
      </Box>
      <Typography sx={{ alignSelf: "flex-start" }} variant="caption" ><strong>หมายเหตุ: </strong>ไฮไลท์อุณหภูมิ &gt; 37.7, Alcohol &gt; 0 </Typography>
      <Popover open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} >
        <AbsentChoice
          {...absentChoiceData}
          handleCheck={handleCheck} />
      </Popover>
    </BoxFC>
  )
}

export default DriverAttend;