import {  Dialog, DialogContent, TextField } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import React, { useCallback, useEffect, useState } from "react"

const TextBoxDialog = ({  dialogOpen, setDialogOpen, onFinish }) => {

  const [jobOrdId, setJobOrdId] = useState("")
  
  const handleDialogOk = useCallback(()=>{
    onFinish(jobOrdId)
    setDialogOpen(false)
  }, [onFinish, setDialogOpen, jobOrdId])

  useEffect(() => {
    if (!dialogOpen) {
      setJobOrdId("")
    }
  }, [dialogOpen])
  
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' 
      >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="สลับข้อมูล"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} alignItems="center">
          <TextField size="small" label="เลขที่ใบงาน"  type="number"
          value={jobOrdId} onChange={e=>setJobOrdId(e.target.value)}/>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"สลับข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default React.memo(TextBoxDialog)